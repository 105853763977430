/*=========================================================================================
  File Name: moduleEcommerceMutations.js
  Description: Ecommerce Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from 'axios'
export default {
    TOGGLE_ITEM_IN_WISH_LIST (state, item) {
      const index = state.wishList.findIndex(i => i.nStokID === item.nStokID)
      if (index < 0) {
        state.wishList.push(item)
      } else {
        state.wishList.splice(index, 1)
      }
    },
    REMOVE_ITEM_FROM_CART (state, item) {
      const index = state.cartItems.findIndex(i => i.nStokID === item.nStokID)
      if (index > -1) { state.cartItems.splice(index, 1) }
    },
    REMOVE_ITEMS(state){
      state.cartItems =[]
    },
    ADD_ITEM_IN_CART (state, item) {
      state.cartItems.push(Object.assign({}, item))
    },
    toplamHesapla(state,item){
      let it = item
      let arr = state.cartItems;
      let lDovizKuru = state.lDovizKuru;
      state.lToplamMiktar =0.0,
      state.lMalBedeli=0.0,
      state.lMalIskontoTutari=0.0,
      state.lToplamKdv=0.0,
      state.lNetTutar=0.00,
      state.bLisans = false,
      state.bFirma = false,
      state.bBilgisayar = false,
      state.bLisansAylik = false,
      state.bLisansYillik = false,
      state.bLisansTemizle =false,
      arr.forEach(function(itm){
        state.lToplamMiktar += parseInt(itm.quantity)
        if (itm.sDovizCinsi ==='USD') {      
          state.lMalBedeli += parseFloat(itm.lFiyat1) * parseInt(itm.quantity) * lDovizKuru
          state.lToplamKdv += ((parseFloat(itm.lFiyat1) * parseInt(itm.quantity)) * lDovizKuru) * parseFloat(itm.nKdvOrani) / 100
        }else{
          state.lMalBedeli += parseFloat(itm.lFiyat1) * parseInt(itm.quantity)
          state.lToplamKdv += (parseFloat(itm.lFiyat1) * parseInt(itm.quantity)) * parseFloat(itm.nKdvOrani) / 100
        }
        if(itm.sKodu =='KarpinYillikLisans'){
          state.bFirma = true,
          state.bLisans = true,
          state.bLisansYillik = true
        }else if(itm.sKodu =='KarpinAylikLisans'){
          state.bFirma = true,
          state.bLisans = true,
          state.bLisansAylik = true
        }else if(itm.sKodu =='LisansTemizleme'){
          state.bFirma = true,
          state.bLisans = true,
          state.bLisansTemizle = true
        }else if(itm.sKodu =='LisansDurdur'){
          state.bFirma = true,
          state.bLisans = true
        }else if(itm.sKodu =='LisansBilgisayar'){
          state.bFirma = true,
          state.bLisans = true,
          state.bBilgisayar = true
        }else if(itm.sKodu =='KarpinKullanici'){
          state.bFirma = true,
          state.bLisans = false
        }else if(itm.sKodu =='KarpinEntegre'){
          state.bFirma = true,
          state.bLisans = false 
        }
        else if(itm.sKodu =='KarpinMobile'){
          state.bFirma = true,
          state.bLisans = false 
        }else if(itm.sKodu =='Turab'){
          state.bFirma = true,
          state.bLisans = false 
        }
        state.lNetTutar= state.lMalBedeli + state.lToplamKdv
      });
      
    },
    UPDATE_ITEM_QUANTITY (state, payload) {
      state.cartItems[payload.index].quantity = payload.quantity
    },
    UPDATE_USD_KURU (state, payload) {
      state.lDovizKuru = payload
    }
  }
  
  
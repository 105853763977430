/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import auth from '@/auth/authService'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  nFirmaID : -3,
  nHesapID: 0,
  sKodu : 'ceddan',
  sAciklama : 'www.ceddan.com',
  sAdres1:'Aydınlıkevler Mah Çağdaş Sokak No:43/A Altındağ / Ankara / Türkiye',
  sAdres2: '',
  sVergiDairesi: 'YAHYAGALİP',
  sVergiNo: '49759718224',
  sSaticiRumuzu: '',
  sSemt: 'Altındağ',
  sIl : 'Ankara',
  sMail: 'ceddan@ceddan.com', 
  sYetkili:'',
  sTelefon:'',
  sPostaKodu:'',
  isUserLoggedIn: () => {
    let isAuthenticated = false

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    if (auth.isAuthenticated() || firebaseCurrentUser) isAuthenticated = true
    else isAuthenticated = false

    return localStorage.getItem('userInfo') && isAuthenticated
  }
}

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'tr': {
        home:'Ana Sayfa',
        about:'Hakkımızda',
        services:'Hizmetler',
        references:'Referanslar',
        faq:'Sık Sorulan Sorular',
        support:'Destek',
        shop:'Mağaza',
        demo:'Canlı Demo',
        odeme:'Ödeme',
        odemeopsiyonlari:'Ödeme Opsiyonları',
        ay:'Ay',
        ara:'Ara',
        cart:'Sepet',
        inyourcart:'Sepetinizde',
        addtocart:'Sepete At',
        viewincart:'Sepette Göster',
        browseshop:'Mağazaya Git',
        cartempty:'Alışveriş Sepetiniz Boş',
        mevcut:'Mevcut',
        instock:'Stoklarda Var',
        miktar:'Miktar',
        fiyat:'Fiyat',
        tutar:'Tutar',
        sayin:'Sayın',
        fisno:'Fiş No',
        kayitno:'Kayıt No',
        teslimat:'Teslimat',
        ucretsizkargo: 'Ücretsiz Kagro',
        kaldir:'Kaldır',
        toplamlar:'Toplamlar',
        toplam:'Toplam',
        iskonto:'İskonto',
        kdv:'Kdv',
        yekun:'Yekün',
        tamamla:'Tamamla',
        tamamlandi:'Tamamlandı',
        devam:'Devam',
        bitir:'Bitir',
        yazdir:"Yazdır",
        eminmisiniz:'Emin Misiniz?',
        product:'Ürün',
        relatedproducts :'Benzer Ürünler',
        peoplealsosearchforthisitems: 'Kullanıcılar Bu Ürünleride Beraberinde Arıyor',
        checkout:'Alışveriş Sepeti',
        contacts:'İletişim',
        whoweare:'Biz Kimiz',
        quicklinks:'Faydalı Linkler',
        humanresource:'İnsan Kaynakları',
        termsofuse:'Üyelik Sözleşmesi',
        privacy:'Gizlilik Politikası KVKK',
        securepayment:'Güvenli Ödeme',
        wheretofindus:'Bizi Nerede Bulabilirsin',
        copyright:'Tüm Hakları Saklıdır',
        ik:'İnsan Kaynakları',
        slogan:'İşiniz için Hızlı ve Akıllı Çözümler!',
        slogan1: 'İşiniz Daha iyi bir Yazılımı hakediyor',
        slogan2:'Daha iyi Yazılım Satış&Kârlılık Artırır',
        slogan3:'İşiniz Daha iyi bir Yazılımı Hakediyor',
        slogan4:'Kullanıcıların uzman olmasını gerektirmez',
        slogan5:'Uzmanlık bilgisi gerektirmeden işinizi daha iyi yönetin',
        slogan6:'We’ll help you achieve your marketing & business goals',
        slogan7:'Karpin ERP İş Yönetimi çözümleriyle İşinizi uçtan uca yönetin.',
        ucretsizdene:'Ücretsiz Deneyin',
        simdidene:'Şimdi Deneyin',
        hikayeyoksadecegercekler:'Hikaye Yok, Sadece Gerçekler',
        ayrintilisatisraporlarielinizde:'Ayrıntılı Satış Raporları Sizin Elinizde',
        uygulamamizisimdiucretsizdeneyin:'Uygulamamızı şimdi ücretsiz deneyin',
        isinizebuyumeicineniyiyardimisunun:'İşinize büyüme için en iyi yardımı sunun',
        isinizicinhemenhareketegecin: 'İşiniz için Hemen Harekete Geçin',
        karpinicinuzmanolmayagerekyok:"Karpin için Uzman olmaya gerek yoktur, Basit Kılavuzlarla herşey kolaydır",
        sektorler:"Marketten Restorana,Zincir Mağazalardan Kobi İşletmelerine,İnşaat'tan Gıda'ya, Otomotiv'den Mobilya'ya, Demir-Çelik'ten Enerji'ye hemen hemen tüm üretim sektörlerinde yeralan şirketlerden, İthalat ve İhracat alanında sektörünün önünde yeralan şirketlerden, Perakende ve Toptan Alım-Satım alanında faaliyet gösteren tüm ticari kuruluşlara, Lojistik, Eğitim,Dershane,Kolej,Sağlık ve Turizm'den Müşavirlik Hizmetlerine kadar tüm hizmet sektöründe her ölçekteki Özel ve Kamu kuruluşlarına yönelik ürünleri ile kullanıcı odaklı özel yazılımlar.",
        thanks:'Teşekkürler!',
        referans:'Referans',
        mutlumusteriler:'Mutlu Müşteriler',
        deneyim:'Deneyim',
        bilgibirikimtecrube:'Bilgi|Birikim|Tecrube',
        uzmanekip:'Uzman Ekip',
        isininuzmanlari:'İşinin Uzmanları',
        proje: 'Proje',
        aciklama:'Açıklama',
        tarih: 'Tarih',
        sehir:'Şehir',
        yil: 'Yıl',
        herkesicinuygunfiyatli:'Herkes için Uygun Fiyatlı',
        egeruygulamadaozelisteginizvarsa:'Eğer uygulamada özel bir isteğiniz var veya e-ticaret sitesi ve sanal pazarlar entegrasyonu planlıyorsanız, lütfen bizimle iletişime geçin!',
        sikcasorulansorular:'Sıkça Sorulan Sorular',
        sorularinizicevaplamakzevktir:'Sorularınızı cevaplamak bizim için bir zevktir.',
        tamamenduyarli:'Tamamen Duyarlı',
        daimaguncel:'Daima Güncel',
        mukemmelmusteridestegi:'Mükemmel Müşteri Desteği',
        istegebagliekstrahizmetler:'İsteğe Bağlı Ekstra Hizmetler',
        sendekatil:'Geçen Yıl 323 işletme katıldı aramıza. Sende Katıl!',
        banaulasin:'Bana Ulaşın',
        bizarayalim:'Biz Arayalım',
        siradanevar: 'Karpin Yazılımda Sırada Ne Var?',
        adiniz:'Adınız',
        telefonnumaraniz:'Telefon Numaranız',
        dahafazla:'Daha Fazla Bilgi Edinin',
        sizinicinhepsibirarada: 'Sizin için hepsi bir arada duyarlı uygulama',
        satisnoktasiyazilimi:'Satış Noktası Yazılımı',
        satisnoktasiyazilimiozellikleri:'Hızlı ve kullanımı kolay, dokunmatik ekran destekli satış ekranı yardımıyla zamandan tasarruf sağlayan Karpin POS ile kasiyerin tek tuşla satış, iade, kampanya, servis ve raporlama gibi her bir uygulamayı aynı ekrandan yönetebilmesi sağlanır.',
        isyonetimiyazilimi:'İş Yönetimi Yazılımı',
        isyonetimiyazilimiozellikleri:'İşletmelerin ihtiyaç duydukları tüm iş süreçlerini entegre bir şekilde sağlayan ticari yazılım KARPİN, Üretimden Sevkiyata, Toptan ve Parekende satış kanallarında gerçekleşen satıştan satış sonrası hizmetlere kadar; bir perakendeci, toptancı ya da üretici firmanın gereksinim duyacağı tüm iş süreçlerini baştan sona kapsamaktadır.',
        mobilyazilim:'Mobil Yazılım',
        mobilyazilimozellikleri:'Akıllı Telefon ve Tablet üzerinden çalışma; Bilgisayar Ortamında yapabileceğiniz Tüm İşlemler Akıllı Telefon ve Tabletlerde',
        baslamayahazirmisin:'Başlamaya Hazırmısın?',
        isinizicingelistiriyoruz:'İşiniz için,Hızlı ve Akıllı Çözümler Geliştiriyoruz',
        basariniziartirmakicin:'Başarınızı artırmak için,',
        gucluuygulamalargelistiriyoruz:'Güçlü uygulamalar geliştiriyoruz',
        tecrubeliuzmanlarimiz:'Tecrübeli Uzmanlarımız',
        satispazarlama:'Satış&Pazarlama',
        yazilimuzmani:'Yazılım Uzmanı',
        direktor:'Direktör',
        teknikuzman:'Teknik Uzman',
        destekmiistiyorsunuz:'Destek mi istiyorsunuz?',
        destekaraclari:'Destek Araçları',
        yuklemeler:'Yüklemeler',
        veriaktarimi:'Veri Aktarımı',
        dokumanlar:'Dökümanlar',
        videolar:'Videolar',
        indir:'indir',
        izle:'İzle',
        sayfayagit:'Sayfaya Git',
        kolonlar:'Kolonlar',
        sayfaismi:'Sayfa İsmi',
        excelden:'Excelden',
        stok:'Stok',
        cari:'Cari',
        musteri:'Müşteri',
        caribakiyesi:'Cari Bakiyesi',
        musteribakiyesi:'Müşteri Bakiyesi',
        aktarimformati:'Aktarım Formatı',
        tumalanlardoluolmali:'Tüm Alanların Dolu Olması Gerekmektedir',
        sayisalalanlardoluolmali:'Sayısal Değerler Boş ise Sıfır (0) girmelisiniz.',
        tarihselalanlardoluolmali:"Tarihsel Değerler Boş ise '01/01/1900' yazmalısınız",
        metinlerdekesmeyok:"Metinlerde Kesme(') ve Virgül(,) bulunmamalıdır.",
        offlineterminalyazilimi:'Offline El Terminali Yazılımı',
        onlineterminalyazilimi:'Online El Terminali Yazılımı',
        posscreenyazilimi:'Dokunmatik Ekran Satış Yazılımı',
        posentegreyazilimi:'Pos Kasa Anlık Satış Okuma',
        kurulumdosyalari:'Kurulum Dosyaları',
        barkodyazicisuruculeri:'Barkod Yazıcı Sürücüleri',
        etiket:'Etiket',
        bayiliksozlesmesi:'Bayilik Sözleşmesi',
        fatura:'Fatura',
        irsaliye:'İrsaliye',
        siparis:'Sipariş',
        ceksenet:'Çek Senet',
        kasa:'Kasa',
        banka:'Banka',
        perakende:'Perakende - Taksitli Satış',
        pesinsatis:'Peşin Satış',
        market:'Market',
        kredikartiislemleri:'Kredi Kartı İşlemleri',
        giderisleme:'Gider İşleme',
        marketanlasmalari:'Market Anlaşmaları',
        marketkasateslimal:'Market Kasa Teslim Al',
        etiketdizaynlari:'Etiket Dizaynları',
        ogrenmekistediginizhangisi:'Öğrenmek İstediğiniz Hangisi?',
        isletmenizibuyutun:'İşletmenizi büyütün',
        trendlereonculuketmekicinplanyapin:'Trendlere Öncülük etmek ve İlerlemek için önceden plan yapın.',
        entegrasyondestegi:'Entegrasyon Desteği',
        derinlemesinearastirmaanalizi: 'Derinlemesine Araştırma Analizi',
        bilimselilerleme:'Bilimsel İlerleme',
        kullanicidostu:'Kullanıcı Dostu',
        kullanicidostuslogan:'Uygulamalar, kullanıcı yetkilendirmesine bağlı olarak için son derece kolay ve değiştirilebilir tasarıma dayanarak oluşturulmuştur.',
        yuksekduyarliaraclar:'Yüksek Duyarlı Araçlar',
        yuksekduyarliaraclarslogan:'Şubeli Çalışma, Çoklu Dil Kullanımı, Döviz Kurlarını Otomatik Çekme, Yetkilendirme, İşlem Geçmişi, Fiyat Tarihçesi ve Sık Kullanılan İşlemler için Kısayol Oluşturma gibi birbirinden güzel araçlar',
        mobiluyumlu:'Tamamen Mobil Cihazlarla Uyumlu',
        mobilslogan:'Bilgisayar ortamında yaptığınız tüm işlemleri Webte Cepte Tablette yapabilirsiniz',
        mobilmodul:"'POS, Cari, Stok, Fatura, İrsaliye, Sipariş, Çek/Senet, Kasa, Müşteri, Printer,Barkod Okuyucu,Raporlar'', '1 Kullanıcı','1 Yıl Lisans'",
        posyazilimi: 'POS Yazılımı',
        posmodul:"'POS, Cari, Stok, Etiket, Fatura, Müşteri, Raporlar, Caller ID, Terazi, Çekmece ve Display ile çalışma','1 Kullanıcı','1 Yıl Ücretsiz Destek'",
        marketyazilimi: 'Market Yazılımı',
        marketmodul:"'Market, POS, Cari, Stok, Fatura, İrsaliye, Sipariş, Barkod, Etiket, Sayım, Raporlar','1 Kullanıcı','1 Yıl Ücretsiz Destek'",
        magazayazilimi:'Mağaza Yazılımı',
        magazamodul:"'Perakende, Müşteri, Sonradan Teslim, Cari, Stok,Fatura, İrsaliye, Sipariş, SMS, Barkod, Etiket, Sayım, Raporlar','1 Kullanıcı','1 Yıl Ücretsiz Destek'",
        lisans:'License',
        populer:'Popüler',
        sinirlierisim:'Sınırlı Erişim',
        sektorel:'ERP, Mağaza Market Depo Üretim Lojistik...Tüm Sektörlerde',
        mobilite:'Mobil, İşiniz Webte Cepte Tablette',
        eticaretentegre:'e-Ticaret ile Entegre, n11|GG|hepsiburada|amazon|...',
        edonusumentegre:'eDönüşüm, e-Fatura|e-Arşiv|e-Defter..',
        fikirlerinizitanimlayin:'Fikirlerinizi Tanımlayın',
        fikirlerinizitanimlayinslogan:'Hedeflerinize ulaşmak için uygulamanızın nasıl performans göstermesini istediğinize ilişkin fikirleri paylaşın.',
        tasarimlarustundecalisma:'Tasarımlar Üstünde Çalışma',
        tasarimlarustundecalismaslogan:'Bilginizi aldıktan sonra, uzmanlarımız son tasarımları hazırlamak için çalışıyorlar.',
        kodlamavegozdengecirme:'Kodlama ve Gözden Geçirme',
        kodlamavegozdengecirmeslogan:'Nihai ürün titiz kodlama ve gözden geçirmeden sonra elde ediliyor.',
        vizyon:'Vizyonumuz',
        misyonumuz:'Misyonumuz',
        arkadascanlisidestek:'Yeni Arkadaş Canlısı Destek',
        danismahatti:'Danışma Hattı',
        gorusbildirin:'Görüşlerinizi Bildirin',
        faturabilgileri:'Fatura Bilgileri', 
        telefondestegi:'Telefon Desteği',
        maildestegi:'Mail Desteği',
        efaturamukellefi:'e-Fatura Mükellefi',
        efatura:'e-Fatura',
        efaturaaciklama:'e-Fatura, faturanın elektronik ortamda oluşturulup saklanabildiği uygulamayla birlikte oluşturulan faturalara verilen isimdir. e-Fatura kağıt fatura ile aynı hukuki niteliklere sahiptir. Sistem Müşteri Vergi Numarası e-fatura Mükellefi ise e-Fatura Değilse e-Arşiv otomatik oluşturmaktadır.',
        earsiv:'e-Arşiv',
        earsivaciklama:'e-Arşiv Fatura, faturanın alıcısına iletilecek olan nüshasının kağıt ya da elektronik olarak diğer nüshasının ise elektronik olarak saklanabildiği uygulamayla birlikte oluşturulan faturalara verilen isimdir. Bir nüshası kağıt olan e-Arşiv Faturanın alıcısına iletimi e-posta, sms gibi yöntemlerle yapılabilmekte ve kesilen faturanın kağıt faturaya dönüştürülmesine isteğe bağlı olarak mükellef tarafından karar verilebilmektedir.',
        eirsaliye:'e-irsaliye',
        eirsaliyeaciklama:'e-irsaliye kağıt irsaliye ile aynı hukuki niteliklere sahip olup öncelikle sevk irsaliyesi yerine kullanılacak ve malı teslim alan tarafa iletilecektir. e-irsaliye’nin kağıt çıktısı ise taşıma irsaliyesi olarak araçta bulundurulacaktır. Firmalar arası e-irsaliye düzenlenebilmesi için hem alıcı hem de satıcı tarafın e-irsaliye uygulamasına kayıtlı olması gerekmektedir.',
        zamantasarrufu:'Zaman Tasarrufu',
        zamantasarrufuaciklama:'Faturanızı bir tıkla müşterinize ulaştırıp tahsilat sürecini hızlandırabilirsiniz. Elektronik ortamda iletilen faturalarınız sayesinde mutabakat gibi süreçlere son verebilir, alacaklarınızı daha hızlı temin edebilirsiniz.',
        gecmisverilereulasimkolayligi:'Geçmiş verilere ulaşım kolaylığı',
        gecmisverilereulasimkolayligiaciklama:'e-Fatura ile faturalarınız elektronik ortamda saklanır. Elektronik imza ve elektronik arşivleme ile veri güvenliğini sağlayabilir ve geçmiş kayıtlarınıza kolayca erişebilirsiniz. Kullanıcı hatalarından doğan külfetten kurtulursunuz.',
        kagitsaklamasikintisininsonaermesi:'Kağıt saklama sıkıntısının sona ermesi',
        kagitsaklamasikintisininsonaermesiaciklama:'Dosyalar arasından fatura aramakla uğraşmadan geçmiş faturalarınıza erişebilirsiniz; böylece fatura kaybının da önüne geçmiş olursunuz. Geçmişe yönelik fatura kayıplarının önüne geçebilir, fiziksel arşivleme, kağıt saklama sıkıntısından kurtulursunuz.',
        maliyetavantaji:'Maliyet Avantajı',
        maliyetavantajiaciklama:'Faturaların basım maliyetinden ve matbaalara ücret ödemekten kurtulur, Mevzuata uyum zorunluluğu sebebiyle ortaya çıkan usulsüzlük cezalarının önüne geçer ve fatura gönderim maliyetinden kurtulursunuz. Böylece müşterilerinize daha hızlı ulaşır, tahsilat sürecinizi hızlandırırsınız.',
        gercekzamanliisanalizleri:'Gerçek zamanlı iş analizleri oluşturma',
        kullanicidostutasarimlar:'Kullanıcı dostu tasarımlar yapma',
        gelecegehazirtaleplereduyarli:'Geleceğe Hazır ve Taleplere Duyarlı olma',
        dinleyenogrenen:'Dinleyen, Öğrenen , Geliştiren ,Uyarlayan olma isteği',
        isimizibuyutme:'İşimizi Büyütme ve Marka bilinci oluşturmak için tüm gereksinimlerinizi karşılamaya çalışıyoruz.',
        eniyiyolisibuyutme:'En iyi yol işi büyütmek',
        herguncalisiyoruz:'Her gün daha yüksek hedeflere ulaşmak için daha güçlü bir iş kurmanıza yardımcı olmak için benzer fikirlere sahip ve yetenekli iş birlikleri arıyoruz.',
        fikirleribirlestir:'Fikirleri Birleştir',
        planyap:'Plan Yap',
        zamaniyonet:'Zamanı Yönet',
        mobilcalis:'Mobil Çalış',
        dahayuksekbuyumeicingelistirildi:'Daha Yüksek İş Büyümesi için Geliştirildi',
        guvenilirhizmetler:'Güvenilir Hizmetler',
        kapsamlidokumanlar:'Kapsamlı Dökümanlar',
        musteridestegi:'Müşteri Desteği',
        garantilibuyume:'Garantili Büyüme',
        entegrasyon:'Entegrasyon',
        musteriyonetimi:'Müşteri Yönetimi',
        gelistirme:'Geliştirme',
        sosyalmedya:'Sosyal Medya',
        guvenlik:'Güvenlik',
        bildirim:'Bildirim',
        optimizasyon:'Platform Optimizasyonu',
        webgelistirme:'Web Geliştirme',
        uygulamagelistirme:'Uygulama Geliştirme',
        arastirma:'Araştırma',
        magazacilik:'Mağazacılık',
        egitimdestek:'Eğitim Destek',
        deneyimliuzmanlar:'Deneyimli Uzmanlar',
        zamanindadestek:'Zamanında Destek',
        uygunfiyatlar:'Uygun Fiyatlar',
        isgelistirme:'İş Geliştirme',
        musteritemsilcisi:'Müşteri Temsilcisi',
        teknikegitim:'Teknik Eğitim',
        harita:'Harita',
        sirketunvani:'Şirket Ünvanı',
        kurulusyili:'Kuruluş Yılı',
        adres:'Adres',
        telefon:'Telefon',
        gsm:'Gsm',
        email:'E-Mail',
        vkn:'VKN',
        faturaadresi:'Fatura Adresi',
        sevkiyatadresi:'Sevkiyat Adresi',
        yetkili:'Yetkili',
        firmakodu:'Firma Kodu',
        firmaadi:'Firma Adı',
        il:'İl',
        vergidairesi:'Vergi Dairesi',
        sektor:'Sektör',
        notlar:'Notlar*',
        lisanslar:'Lisanslar',
        secilenlisanslar:'Seçilen Lisanslar',
        kartno:'Kart No',
        kartsahibi:'Kart Sahibi',
        taksit:'Taksit',
        havale:'Havale /EFT',
        kapidaodeme:'Kapıda Ödeme',
        kargoucreti:'Kargo Ücreti',
        ucretsiz:'Ücretsiz',
        adet:'Adet',
        bilgisayar:'Bilgisayar',
        nakit:'Nakit',
        kredikarti:'Kredi Kartı',
        login:'Login',
        register:'Yeni Kayıt',
        rememberme:'Beni Hatırla',
        loginaciklama:'Tekrar Hoşgeldin, Lütfen hesabınla Giriş Yap.'
        


        






    },
    'en': {
        home:'Home',
        about:'About',
        services:'Services',
        references:'References',
        faq:'Faq',
        support:'Support',
        shop:'Shop',
        demo:'Live Demo',
        odeme:'Payment',
        odemeopsiyonlari:'Payment Options',
        ay:'Month',
        ara:'Search',
        cart:'Cart',
        inyourcart:'In Your Cart',
        addtocart:'Add to Cart',
        viewincart:'View in Cart',
        browseshop:'Browse Shop',
        cartempty:"You don't have any items in your cart.",
        mevcut:'Available',
        instock:"in Stock",
        miktar:'Qty',
        fiyat:'Price',
        tutar:'Amount',
        sayin:'Dear',
        fisno:'Doc No',
        kayitno:'Record No',
        teslimat:'Delivery',
        ucretsizkargo: 'Free Shipping',
        kaldir:'Remove',
        toplamlar:'Totals',
        toplam:'Total',
        iskonto:'Discount',
        kdv:'Vat',
        yekun:'Net Total',
        tamamla:'Complete',
        tamamlandi:'Completed',
        devam:'Continue',
        bitir:'Finish',
        yazdir:"Print",
        eminmisiniz:'Are you Sure?',
        product:'Product',
        relatedproducts:'Related Products',
        peoplealsosearchforthisitems: 'People Also Search for This Items',
        checkout:'Checkout',
        contacts:'Contact',
        whoweare:'Who we are',
        quicklinks:'Quick Links',
        humanresource:'Human Resources',
        termsofuse:'Terms of Use',
        privacy:'Privacy',
        securepayment:'Secure Payment',
        wheretofindus:'Where to Find Us',
        copyright:'All Rights Reserved',
        ik:'Human Resoruces',
        slogan:'Fast and Smart Solutions for Your Business!',
        slogan1:'Your Business Deserves Better Software',
        slogan2:'Better software promotes sales & profits',
        slogan3:'Your business deserves better software',
        slogan4:"Karpin doesn't require users to be experts.",
        slogan5:"Manage your business better without requiring expert knowledge",
        slogan6:'We’ll help you achieve your marketing & business goals',
        slogan7:'Manage your business end-to-end with Karpin ERP Business Management solutions.',
        ucretsizdene:'Try Free',
        simdidene:'Try Now',
        hikayeyoksadecegercekler:'No stories, facts only',
        ayrintilisatisraporlarielinizde:'In-depth Sales Reports Available at Your Hand',
        uygulamamizisimdiucretsizdeneyin:'Free Try of our app now',
        isinizebuyumeicineniyiyardimisunun:'Offer your business with the best assistance for growth',
        isinizicinhemenhareketegecin:'Take Action for your business now',
        karpinicinuzmanolmayagerekyok:'No need to be an expert for Karpin,Everything is easy with simple guides',
        sektorler:"Retail and Wholesale from companies that take place all production sectors from Market to Restaurant, from Chain Stores to SME Enterprises, from Construction to Food, from Automotive to Furniture, from Iron and Steel to Energy, from companies that are in front of the sector in the field of Import and Export. User oriented private software with products for all sizes of Private and Public institutions in all service sectors from Logistics, Education, Classroom, College, Health and Tourism to Consultancy Services to all commercial organizations operating in the field of Purchase and Sale.",
        thanks:'Thanks!',
        referans:'Reference',
        mutlumusteriler:'Happy Customers',
        deneyim:'Experience',
        bilgibirikimtecrube:'Information | Knowledge | Experience',
        uzmanekip:'Expert Team',
        isininuzmanlari:'Professional of the Work',
        proje: 'Project',
        aciklama: 'Description',
        tarih:'Date',
        sehir:'City',
        yil:'Year',
        herkesicinuygunfiyatli:'Affordable for everyone!',
        egeruygulamadaozelisteginizvarsa:'If you have a special request in the app or plan to integrate e-commerce site and virtual markets, please contact us!',
        sikcasorulansorular:'Frequently Asked Questions',
        sorularinizicevaplamakzevktir:'It’s our pleasure to answer any questions you have',
        tamamenduyarli:'Completely Responsive',
        daimaguncel:'Always up to date',
        mukemmelmusteridestegi:'Excellent Customer Support',
        istegebagliekstrahizmetler:'On-demand Extra Services',
        sendekatil:'Last year, 323 businesses joined us. Join Them!',
        banaulasin:'Call Me',
        bizarayalim:"We'll Call",
        siradanevar: 'What’s next in Karpin?',
        adiniz:'Name',
        telefonnumaraniz:'Your Phone',
        dahafazla:'Learn More',
        sizinicinhepsibirarada: 'All-in-one responsive app for you',
        satisnoktasiyazilimi:'Point Of Sale Software',
        satisnoktasiyazilimiozellikleri:'With Karpin POS, which is fast and easy to use and saves time with the help of touch screen supported sales screen, the cashier is able to manage each application such as one-touch sales, return, campaign, service and reporting from the same screen.',
        isyonetimiyazilimi:'Business Managment Software',
        isyonetimiyazilimiozellikleri:'Commercial software, KARPİN, which provides all the business processes that businesses need in an integrated way, from production to shipping, wholesale and retail sales to after-sales services; It covers all the business processes that a retailer, wholesaler or manufacturer company will need.',
        mobilyazilim:'Mobil Software',
        mobilyazilimozellikleri:'Working via Smartphone and Tablet; All Transactions You Can Do in Computer Environment on Smart Phones and Tablets',
        baslamayahazirmisin:'Are you ready to Start?',
        isinizicingelistiriyoruz:'For your business,We Develop Fast and Smart Solutions',
        basariniziartirmakicin:'To increase your success,',
        gucluuygulamalargelistiriyoruz:'we develop powerful applications',
        tecrubeliuzmanlarimiz:'Our experience experts',
        satispazarlama:'Sales&Marketing',
        yazilimuzmani:'Software Expert',
        direktor:'Director',
        teknikuzman:'Technic Expert',
        destekmiistiyorsunuz:'Want an support?',
        destekaraclari:'Support Tools',
        yuklemeler:'Setups',
        veriaktarimi:'Import Files',
        dokumanlar:'Documents',
        videolar:'Videos',
        indir:'Download',
        izle:'watch',
        sayfayagit:'Go To Page',
        kolonlar:'Columns',
        sayfaismi:'Page Name',
        excelden:'from Excel',
        stok:'Product',
        cari:'Company',
        musteri:'Customer',
        caribakiyesi:'Company Balance',
        musteribakiyesi:'Customer Balance',
        aktarimformati:'Import Format',
        tumalanlardoluolmali:'All Fields Must Be Filled',
        sayisalalanlardoluolmali:'If the Numerical Values are Null, you must enter Zero (0).',
        tarihselalanlardoluolmali:"If Historical Values are Null, you should write '01 / 01/1900 '",
        metinlerdekesmeyok:"Texts must not contain apostrophes (') and commas (,)",
        offlineterminalyazilimi:'Offline Hand Terminal Software',
        onlineterminalyazilimi:'Online Hand Terminal Software',
        posscreenyazilimi:'Touch Screen Sales Software',
        posentegreyazilimi:'Pos Cash Register Instant Sales Read',
        kurulumdosyalari:'Setup Files',
        barkodyazicisuruculeri:'Barcode Printer Drivers',
        etiket:'Label',
        bayiliksozlesmesi:'Dealership Aggrement',
        fatura:'Invoice',
        irsaliye:'Wayybill',
        siparis:'Order',
        ceksenet:'Cheque',
        kasa:'Cash Safe',
        banka:'Bank',
        perakende:'Retail',
        pesinsatis:'Cash Sales',
        market:'Market',
        kredikartiislemleri:'Credit Card Transactions',
        giderisleme:'Expenses Transactions',
        marketanlasmalari:'Market Aggrements',
        marketkasateslimal:'Check Market Cashier Money',
        etiketdizaynlari:'Label Designs',
        ogrenmekistediginizhangisi:'Which one do you want to learn?',
        isletmenizibuyutun:'Expand Your Business',
        trendlereonculuketmekicinplanyapin:'Plan ahead to make progress and lead the trend.',
        entegrasyondestegi:'Integration Support',
        derinlemesinearastirmaanalizi: 'In-depth research analysis',
        bilimselilerleme:'Scientifically progress',
        kullanicidostu:'User Friendly',
        kullanicidostuslogan:'Applications are built on an extremely easy and changeable design for user authorization.',
        yuksekduyarliaraclar:'Highly Responsive Tools',
        yuksekduyarliaraclarslogan:'Beautiful tools such as Multi-branch, Multi-Language Use, Auto-Exchange Currency, Authorization, Transaction History, Price History and Creating Shortcuts for Frequently Used Transactions',
        mobiluyumlu:'Completely Mobile Ready', 
        mobilslogan:'You can do all the things you do on the computer on the web, on the mobile, on the tablet.',
        mobilmodul:"'POS, Company, Product, Invoice, Waybill, Order, Cheque, Cash, Customer, Printer,Barcode Scanner,Reports', '1 User','1 Year License'",
        posyazilimi: 'POS Software',
        posmodul:"'POS, Company, Product, Label, Invoice, Customer, Reports, Caller ID, Scale, Drawer and Display with work','1 User','1 Year Free Support'",
        marketyazilimi: 'Market Software',
        marketmodul:"'Market, POS, Company, Product, Invoice, Wayybill, Order, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        magazayazilimi:'Retail Software',
        magazamodul:"'Retail, Customer, After Delivery, Company, Product, Invoice, Waybill, Order, SMS, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        lisans:'License',
        populer:'Popular Choice',
        sinirlierisim:'Limited',
        sektorel:'ERP, Store Market Warehouse Production Logistics ... in All Sectors',
        mobilite:'Mobile, Your Business on the Web, Mobile, Tablet',
        eticaretentegre:'Integrated with e-Commerce, n11|GG|hepsiburada|amazon |...',
        edonusumentegre:'e-Transformation, e-Invoice|e-Archive|e-Ledger..',
        fikirlerinizitanimlayin:'Define your ideas',
        fikirlerinizitanimlayinslogan:'Share ideas of how you would like your app to perform to achieve goals.',
        tasarimlarustundecalisma:'Work on designs',
        tasarimlarustundecalismaslogan:'After getting your brief, our experts work on crafting the final designs.',
        kodlamavegozdengecirme:'Coding and Review',
        kodlamavegozdengecirmeslogan:'The final product is obtained from meticulous coding and review.',
        vizyon:'Our Vision',
        misyonumuz:'Our Mission',
        arkadascanlisidestek:'New Friendly Support',
        danismahatti:'Hot Line',
        gorusbildirin:'Give Your Feedback',
        faturabilgileri:'Invoice Info',
        telefondestegi:'Telephone Support',
        maildestegi:'Mail Support',
        efaturamukellefi:'e-Invoice Member',
        efatura:'e-Invoice',
        efaturaaciklama:'e-Invoice is the name given to the invoices created with the application where the invoice can be created and stored electronically. E-Invoice has the same legal qualities as paper invoices. If the System Customer Tax Number e-invoice Payer is not e-Invoice, e-Archive automatically creates.',
        earsiv:'E-Archive',
        earsivaciklama:'e-Archive Invoice is the name given to the invoices created with the application, in which the copy to be sent to the recipient of the invoice can be stored electronically or the other copy can be stored electronically. The e-Archive Invoice, which is a copy of the paper, can be delivered to the recipient by methods such as e-mail and sms, and the taxpayer can decide to convert the invoice into a paper invoice.',
        eirsaliye:'e-Dispatch',
        eirsaliyeaciklama:'e-Dispatch has the same legal qualities as the paper waybill, and will be used primarily instead of the dispatch note and will be forwarded to the receiving party. The paper output of the e-dispatch will be kept in the vehicle as the waybill. In order to arrange e-dispatch between companies, both the buyer and the seller must be registered in the e-dispatch application.',
        zamantasarrufu:'Saving Time',
        zamantasarrufuaciklama:'You can deliver your invoice to your customer with a click and speed up the collection process. Thanks to your invoices transmitted electronically, you can end processes such as reconciliation and obtain your receivables faster.',
        gecmisverilereulasimkolayligi:'Easy access to historical data',
        gecmisverilereulasimkolayligiaciklama:'With e-Invoice, your invoices are stored electronically. With electronic signature and electronic archiving, you can secure data and easily access your past records. You get rid of the burden of user errors.',
        kagitsaklamasikintisininsonaermesi:'End of paper storage problem',
        kagitsaklamasikintisininsonaermesiaciklama:'You can access your past invoices without having to search for invoices among the files; Thus, you can prevent loss of invoices. You can prevent past invoice losses and get rid of physical archiving and paper storage problems.',
        maliyetavantaji:'Cost Advantage',
        maliyetavantajiaciklama:'You get rid of the cost of printing the invoices and paying the printing presses, you avoid the irregularity penalties that arise due to the obligation to comply with the legislation and you avoid the cost of sending invoices. Thus, you reach your customers faster and speed up your collection process.',
        gercekzamanliisanalizleri:'Receive real-time business analytics',
        kullanicidostutasarimlar:'Making user-friendly designs',
        gelecegehazirtaleplereduyarli:'Being Ready for the Future and Responsive to Demands',
        dinleyenogrenen:'It is to be  listening, learning, developing, adapting',
        isimizibuyutme:'We strive to meet all your needs to grow our business and create brand awareness.',
        eniyiyolisibuyutme:'The best way grow the business',
        herguncalisiyoruz:'We bring on like-minded and talented members to help you grow a stronger business for fiercely reaching towards higher goals every day.',
        fikirleribirlestir:'Connect Ideas',
        planyap:'Make Schedule',
        zamaniyonet:'Manage Time',
        mobilcalis:'Working Mobile',
        dahayuksekbuyumeicingelistirildi:'Developed for Higher Business Growth',
        guvenilirhizmetler:'Reliable Services',
        kapsamlidokumanlar:'Extensive Documents',
        musteridestegi:'Customer Assistance',
        garantilibuyume:'Guaranteed Growth',
        entegrasyon:'Integration',
        musteriyonetimi:'Employee Managment',
        gelistirme:'Development',
        sosyalmedya:'Social Media',
        guvenlik:'Security',
        bildirim:'Notification',
        optimizasyon:'Platform Optimization',
        webgelistirme:'Web Development',
        uygulamagelistirme:'Application Development',
        arastirma:'Research',
        magazacilik:'Retail',
        egitimdestek:'Education Support',
        deneyimliuzmanlar:'Experienced Experts',
        zamanindadestek:'On-Time Support',
        uygunfiyatlar:'Affordable Prices',
        isgelistirme:'Dedicated Attitude',
        musteritemsilcisi:'Customer Representative',
        teknikegitim:'Technical Training',
        harita:'Map',
        sirketunvani:'Company Title',
        kurulusyili:'Foundation Year',
        adres:'Address',
        telefon:'Telephone',
        gsm:'Mobile Phone',
        email:'E-Mail',
        vkn:'Tax identification number',
        faturaadresi:'Billing address',
        sevkiyatadresi:'Shipping address',
        yetkili:'Authorized Person',
        firmakodu:'Company Code',
        firmaadi:'Company Name',
        il:'Province',
        vergidairesi:'Tax Office',
        sektor:'Sector',
        notlar:'Notes*',
        lisanslar:'Licensing',
        secilenlisanslar:'Selected Licenses',
        kartno:'Card Number',
        kartsahibi:'Card Holder',
        taksit:'Hire',
        havale:'Money Order / EFT',
        kapidaodeme:'Cash On Delivery',
        kargoucreti:'Shipping Fee',
        ucretsiz:'Free',
        adet:'PC',
        bilgisayar:'Computer',
        nakit:'Cash',
        kredikarti:'Credit Card',
        login:'Login',
        register:'Register',
        rememberme:'Remember Me',
        loginaciklama:'Welcome back, please login to your account.'


    },
    ar:{
        home:'الصفحة الرئيسية',
        about:'حول',
        services:'خدمات',
        references:'المراجع',
        faq:'التعليمات',
        support:'الدعم',
        shop:'متجر',
        demo:'عرض حي',
        odeme:'دفع',
        odemeopsiyonlari:'خيارات الدفع',
        ay:'شهر',
        ara:'بحث',
        cart:'عربة التسوق',
        inyourcart:'في سلة التسوق الخاصة بك',
        addtocart:'أضف إلى السلة',
        viewincart:'عرض في سلة التسوق',
        browseshop:'تصفح المتجر',
        cartempty:"ليس لديك أي عناصر في سلة التسوق الخاصة بك.",
        mevcut:'متاح',
        instock:'في المخزن',
        miktar:'الكمية',
        fiyat:'السعر',
        tutar:'كمية',
        sayin:'العزيز',
        fisno:'رقم الوثيقة',
        kayitno:'عدد ريج',
        teslimat:'توصيل',
        ucretsizkargo: 'الشحن مجانا',
        kaldir:'إزالة',
        toplamlar:'المجاميع',
        toplam:'مجموع',
        iskonto:'خصم',
        kdv:'ضريبة القيمة المضافة',
        yekun:'المجموع الصافي',
        tamamla:'اكتمال',
        tamamlandi:'منجز',
        devam:'استمر',
        bitir:'إنهاء',
        yazdir:"طباعة",
        eminmisiniz:'هل أنت واثق؟',
        product:'المنتج',
        relatedproducts:'منتجات ذات صله',
        peoplealsosearchforthisitems: 'يبحث الأشخاص أيضًا عن هذه العناصر',
        checkout:'الدفع',
        contacts:'اتصل',
        whoweare:'من نحن',
        quicklinks:'روابط سريعة',
        humanresource:'الموارد البشرية',
        termsofuse:'تعليمات الاستخدام',
        privacy:'خصوصية',
        securepayment:'دفع امن',
        wheretofindus:'أين تجدنا',
        copyright:'كل الحقوق محفوظة',
        ik:'الموارد البشرية',
        slogan:'حلول سريعة وذكية لأعمالك!',
        slogan1:'عملك يستحق برامج أفضل',
        slogan2:'أفضل البرامج تعزز المبيعات والأرباح',
        slogan3:'عملك يستحق برامج أفضل',
        slogan4:"لا يتطلب Karpin أن يكون المستخدمون خبراء.",
        slogan5:"إدارة عملك بشكل أفضل دون الحاجة إلى معرفة الخبراء",
        slogan6:'سنساعدك في تحقيق أهدافك التسويقية والتجارية',
        slogan7:'قم بإدارة أعمالك من البداية إلى النهاية مع حلول Karpin ERP لإدارة الأعمال.',
        ucretsizdene:'حاول مجانا',
        simdidene:'جرب الآن',
        hikayeyoksadecegercekler:'لا قصص ، حقائق فقط',
        ayrintilisatisraporlarielinizde:'تقارير المبيعات المتعمقة المتاحة في يدك',
        uygulamamizisimdiucretsizdeneyin:'تحميل مجاني من التطبيق لدينا الآن',
        isinizebuyumeicineniyiyardimisunun:'تقدم لعملك أفضل مساعدة للنمو',
        isinizicinhemenhareketegecin:'اتخاذ الإجراءات اللازمة لعملك الآن',
        karpinicinuzmanolmayagerekyok:'لا حاجة إلى أن تكون خبيرا في Karpin ، كل شيء سهل مع أدلة بسيطة',
        sektorler:"البيع بالتجزئة والبيع بالجملة من الشركات التي تجري جميع قطاعات الإنتاج من السوق إلى المطاعم ، ومن متاجر السلسلة إلى الشركات الصغيرة والمتوسطة ، ومن الإنشاءات إلى الأغذية ، ومن السيارات إلى الأثاث ، ومن الحديد والصلب إلى الطاقة ، ومن الشركات التي تواجه القطاع مجال الاستيراد والتصدير. برنامج خاص موجه نحو المستخدم مع منتجات لجميع أحجام المؤسسات الخاصة والعامة في جميع قطاعات الخدمات من الخدمات اللوجستية والتعليم والفصول الدراسية والكلية والصحة والسياحة إلى الخدمات الاستشارية لجميع المؤسسات التجارية العاملة في مجال الشراء والبيع.",
        thanks:'شكر!',
        referans:'مرجع',
        mutlumusteriler:'الزبائن سعداء',
        deneyim:'تجربة',
        bilgibirikimtecrube:'معلومات | المعرفة | تجربة',
        uzmanekip:'فريق الخبراء',
        isininuzmanlari:'المهنية في العمل',
        proje: 'مشروع',
        aciklama: 'وصف',
        tarih:'تاريخ',
        sehir:'مدينة',
        yil:'عام',
        herkesicinuygunfiyatli:'بأسعار معقولة للجميع!',
        egeruygulamadaozelisteginizvarsa:'إذا كان لديك طلب خاص في التطبيق أو تخطط لدمج موقع التجارة الإلكترونية والأسواق الافتراضية ، يرجى الاتصال بنا!',
        sikcasorulansorular:'أسئلة مكررة',
        sorularinizicevaplamakzevktir:'إنه لمن دواعي سرورنا الإجابة على أي أسئلة لديك',
        tamamenduyarli:'مستجيبة تماما',
        daimaguncel:'دائما ما يصل إلى التاريخ',
        mukemmelmusteridestegi:'دعم عملاء ممتاز',
        istegebagliekstrahizmetler:'خدمات إضافية عند الطلب',
        sendekatil:'في العام الماضي ، انضم إلينا 323 شركة. انضم إليهم!',
        banaulasin:'كلمني',
        bizarayalim:"سوف نتصل",
        siradanevar: 'ما هو التالي في كاربين؟',
        adiniz:'اسم',
        telefonnumaraniz:'هاتفك',
        dahafazla:'أعرف أكثر',
        sizinicinhepsibirarada: 'الكل في واحد التطبيق تستجيب لك',
        satisnoktasiyazilimi:'نقطة بيع البرمجيات',
        satisnoktasiyazilimiozellikleri:'مع Karpin POS ، وهو سريع وسهل الاستخدام ويوفر الوقت بمساعدة شاشة مبيعات مدعومة بشاشة تعمل باللمس ، يستطيع أمين الصندوق إدارة كل تطبيق مثل المبيعات بلمسة واحدة والعائد والحملة والخدمة وإعداد التقارير من نفس الشاشة .',
        isyonetimiyazilimi:'برامج إدارة الأعمال',
        isyonetimiyazilimiozellikleri:'البرامج التجارية ، KARPİN ، التي توفر جميع العمليات التجارية التي تحتاجها الشركات بطريقة متكاملة ، من الإنتاج إلى الشحن ومبيعات الجملة والتجزئة إلى خدمات ما بعد البيع ؛ وهو يغطي جميع العمليات التجارية التي سيحتاجها بائع التجزئة أو تاجر الجملة أو الشركة المصنعة.',
        mobilyazilim:'برامج المحمول',
        mobilyazilimozellikleri:'العمل عبر الهاتف الذكي والكمبيوتر اللوحي. جميع المعاملات التي يمكنك القيام بها في بيئة الكمبيوتر على الهواتف الذكية والأجهزة اللوحية',
        baslamayahazirmisin:'هل انت مستعد للبدء؟',
        isinizicingelistiriyoruz:'لعملك ، نقوم بتطوير حلول سريعة وذكية',
        basariniziartirmakicin:'لزيادة نجاحك ،',
        gucluuygulamalargelistiriyoruz:'نحن نطور تطبيقات قوية',
        tecrubeliuzmanlarimiz:'خبراء تجربتنا',
        satispazarlama:'المبيعات والتسويق',
        yazilimuzmani:'خبير برمجيات',
        direktor:'مخرج',
        teknikuzman:'خبير تقني',
        destekmiistiyorsunuz:'تريد الدعم؟',
        destekaraclari:'أدوات الدعم',
        yuklemeler:'الاجهزة',
        veriaktarimi:'استيراد الملفات',
        dokumanlar:'مستندات',
        videolar:'أشرطة فيديو',
        indir:'تحميل',
        izle:'راقب',
        sayfayagit:'انتقل إلى صفحة',
        kolonlar:'أعمدة',
        sayfaismi:'اسم الصفحة',
        excelden:'من اكسل',
        stok:'المنتج',
        cari:'شركة',
        musteri:'زبون',
        caribakiyesi:'رصيد الشركة',
        musteribakiyesi:'رصيد العملاء',
        aktarimformati:'تنسيق الاستيراد',
        tumalanlardoluolmali:'يجب ملء كل الفراغات',
        sayisalalanlardoluolmali:'إذا كانت القيم العددية خالية ، فيجب عليك إدخال Zero (0).',
        tarihselalanlardoluolmali:"إذا كانت القيم التاريخية خالية ، فيجب عليك كتابة '01/01/1900'",
        metinlerdekesmeyok:"يجب ألا تحتوي النصوص على الفواصل العليا (') والفواصل (،)",
        offlineterminalyazilimi:'حاليا البرامج الطرفية اليد',
        onlineterminalyazilimi:'ومن ناحية البرمجيات الطرفية',
        posscreenyazilimi:'شاشة لمس مبيعات البرمجيات',
        posentegreyazilimi:'نقاط البيع النقدية تسجيل المبيعات الفورية اقرأ',
        kurulumdosyalari:'ملفات الإعداد',
        barkodyazicisuruculeri:'برامج تشغيل طابعة الباركود',
        etiket:'ضع الكلمة المناسبة',
        bayiliksozlesmesi:'اتفاق بيع',
        fatura:'فاتورة',
        irsaliye:'بيان الشحنة',
        siparis:'طلب',
        ceksenet:'التحقق من',
        kasa:'النقدية آمنة',
        banka:'بانكا',
        perakende:'قطاعي',
        pesinsatis:'البيع نقدا',
        market:'سوق',
        kredikartiislemleri:'معاملات بطاقات الائتمان',
        giderisleme:'مصاريف المعاملات',
        marketanlasmalari:'اتفاقيات السوق',
        marketkasateslimal:'تحقق السوق أمين الصندوق المال',
        etiketdizaynlari:'تصاميم الملصقات',
        ogrenmekistediginizhangisi:'أي واحد تريد أن تتعلم؟',
        isletmenizibuyutun:'توسيع عملك',
        trendlereonculuketmekicinplanyapin:'خطط للمستقبل لإحراز تقدم وقيادة الاتجاه.',
        entegrasyondestegi:'دعم التكامل',
        derinlemesinearastirmaanalizi: 'تحليل البحوث المتعمقة',
        bilimselilerleme:'التقدم العلمي',
        kullanicidostu:'سهل الاستخدام',
        kullanicidostuslogan:'التطبيقات مبنية على تصميم سهل للغاية ومتغير لترخيص المستخدم.',
        yuksekduyarliaraclar:'أدوات استجابة للغاية',
        yuksekduyarliaraclarslogan:'أدوات جميلة مثل الفروع المتعددة ، الاستخدام متعدد اللغات ، التبادل التلقائي للعملة ، التخويل ، سجل المعاملات ، سجل الأسعار وإنشاء اختصارات للمعاملات التي يكثر استخدامها',
        mobiluyumlu:'تماما المحمول جاهز', 
        mobilslogan:'يمكنك القيام بكل الأشياء التي تقوم بها على الكمبيوتر على شبكة الإنترنت ، على الهاتف المحمول ، على الجهاز اللوحي.',
        mobilmodul:"'POS, Company, Product, Invoice, Waybill, Order, Cheque, Cash, Customer, Printer,Barcode Scanner,Reports', '1 User','1 Year License'",
        posyazilimi: 'POS Software',
        posmodul:"'POS, Company, Product, Label, Invoice, Customer, Reports, Caller ID, Scale, Drawer and Display with work','1 User','1 Year Free Support'",
        marketyazilimi: 'Market Software',
        marketmodul:"'Market, POS, Company, Product, Invoice, Wayybill, Order, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        magazayazilimi:'Retail Software',
        magazamodul:"'Retail, Customer, After Delivery, Company, Product, Invoice, Waybill, Order, SMS, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        lisans:'رخصة',
        populer:'خيار شعبي',
        sinirlierisim:'محدود',
        sektorel:'تخطيط موارد المؤسسات ، سوق المستودعات الإنتاج اللوجستي ... في جميع القطاعات',
        mobilite:'المحمول ، عملك على الويب ، المحمول ، الكمبيوتر اللوحي',
        eticaretentegre:'متكامل مع التجارة الإلكترونية ، n11|GG|hepsiburada|amazon|...',
        edonusumentegre:'التحويل الإلكتروني ، الفاتورة الإلكترونية | الأرشفة الإلكترونية | دفتر الأستاذ الإلكتروني ..',
        fikirlerinizitanimlayin:'حدد أفكارك',
        fikirlerinizitanimlayinslogan:'شارك أفكارًا حول الطريقة التي تريد أن يعمل بها تطبيقك لتحقيق الأهداف.',
        tasarimlarustundecalisma:'العمل على التصاميم',
        tasarimlarustundecalismaslogan:'بعد الحصول على نبذة مختصرة ، يعمل خبراؤنا على صياغة التصميمات النهائية.',
        kodlamavegozdengecirme:'الترميز والمراجعة',
        kodlamavegozdengecirmeslogan:'يتم الحصول على المنتج النهائي من الترميز الدقيق والمراجعة.',
        vizyon:'رؤيتنا',
        misyonumuz:'مهمتنا',
        arkadascanlisidestek:'دعم ودية جديدة',
        danismahatti:'الخط الساخن',
        gorusbildirin:'تقديم ملاحظاتك',
        faturabilgileri:'معلومات الفاتورة',
        telefondestegi:'الدعم عبر الهاتف',
        maildestegi:'دعم البريد',
        efaturamukellefi:'عضو الفاتورة الإلكترونية',
        efatura:'ه-الفاتورة',
        efaturaaciklama:'الفاتورة الإلكترونية هي الاسم الذي يطلق على الفواتير التي تم إنشاؤها باستخدام التطبيق حيث يمكن إنشاء الفاتورة وتخزينها إلكترونيًا. الفاتورة الإلكترونية لها نفس الصفات القانونية مثل الفواتير الورقية. إذا لم تكن الفاتورة الإلكترونية لرقم ضريبة العميل في نظام Payer فاتورة إلكترونية ، فسيتم إنشاء الأرشفة الإلكترونية تلقائيًا.',
        earsiv:'الأرشيف الإلكتروني',
        earsivaciklama:'فاتورة الأرشفة الإلكترونية هي الاسم الذي يطلق على الفواتير التي تم إنشاؤها بواسطة التطبيق ، حيث يمكن تخزين النسخة المراد إرسالها إلى مستلم الفاتورة إلكترونيًا أو يمكن تخزين النسخة الأخرى إلكترونيًا. يمكن تسليم فاتورة الأرشفة الإلكترونية ، وهي نسخة من الورقة ، إلى المستلم بطرق مثل البريد الإلكتروني والرسائل القصيرة ، ويمكن لدافعي الضرائب أن يقرر تحويل الفاتورة إلى فاتورة ورقية.',
        eirsaliye:'الإرسال الإلكتروني',
        eirsaliyeaciklama:'لدى الإرسالية نفس الصفات القانونية مثل بوليصة الشحن الورقية ، وسيتم استخدامها في المقام الأول بدلاً من مذكرة الإرسال وسيتم إرسالها إلى الطرف المتلقي. سيتم الاحتفاظ بإخراج الورق الخاص بالإرسال الإلكتروني في السيارة باعتباره بوليصة الشحن. من أجل ترتيب الإرسال الإلكتروني بين الشركات ، يجب تسجيل كل من البائع والمشتري في تطبيق الإرسال الإلكتروني.',
        zamantasarrufu:'توفير الوقت',
        zamantasarrufuaciklama:'يمكنك تسليم فاتورتك إلى عميلك بنقرة وتسريع عملية التجميع. بفضل فواتيرك المنقولة إلكترونياً ، يمكنك إنهاء العمليات مثل التسوية والحصول على مستحقاتك بشكل أسرع.',
        gecmisverilereulasimkolayligi:'سهولة الوصول إلى البيانات التاريخية',
        gecmisverilereulasimkolayligiaciklama:'باستخدام الفاتورة الإلكترونية ، يتم تخزين فواتيرك إلكترونيًا. باستخدام التوقيع الإلكتروني والأرشفة الإلكترونية ، يمكنك تأمين البيانات والوصول بسهولة إلى سجلاتك السابقة. تتخلص من عبء أخطاء المستخدم.',
        kagitsaklamasikintisininsonaermesi:'نهاية مشكلة تخزين الورق',
        kagitsaklamasikintisininsonaermesiaciklama:'يمكنك الوصول إلى الفواتير السابقة دون الحاجة إلى البحث عن الفواتير بين الملفات ؛ وبالتالي ، يمكنك منع فقدان الفواتير. يمكنك منع خسائر الفاتورة السابقة والتخلص من مشاكل الأرشفة المادية وتخزين الورق.',
        maliyetavantaji:'ميزة التكلفة',
        maliyetavantajiaciklama:'تتخلص من تكلفة طباعة الفواتير ودفع المطابع ، وتجنب عقوبات المخالفة التي تنشأ بسبب الالتزام بالامتثال للتشريع وتجنب تكلفة إرسال الفواتير. وبالتالي ، يمكنك الوصول إلى عملائك بشكل أسرع وتسريع عملية التجميع الخاصة بك.',
        gercekzamanliisanalizleri:'تلقي تحليلات العمل في الوقت الحقيقي',
        kullanicidostutasarimlar:'صنع تصاميم سهلة الاستخدام',
        gelecegehazirtaleplereduyarli:'الاستعداد للمستقبل والاستجابة للطلبات',
        dinleyenogrenen:'هو أن يكون الاستماع ، والتعلم ، وتطوير ، والتكيف',
        isimizibuyutme:'نحن نسعى جاهدين لتلبية جميع الاحتياجات الخاصة بك لتنمية أعمالنا وخلق الوعي بالعلامة التجارية.',
        eniyiyolisibuyutme:'أفضل طريقة تنمو الأعمال',
        herguncalisiyoruz:'نأتي بأعضاء متشابهين في التفكير وموهوبين لمساعدتك في تنمية أعمال أقوى للوصول إلى الأهداف العليا كل يوم.',
        fikirleribirlestir:'ربط الأفكار',
        planyap:'جعل الجدول الزمني',
        zamaniyonet:'إدارة الوقت',
        mobilcalis:'العمل المحمول',
        dahayuksekbuyumeicingelistirildi:'وضعت لنمو الأعمال العليا',
        guvenilirhizmetler:'خدمات موثوقة',
        kapsamlidokumanlar:'وثائق واسعة النطاق',
        musteridestegi:'مساعدة العملاء',
        garantilibuyume:'نمو مضمون',
        entegrasyon:'دمج',
        musteriyonetimi:'إدارة شؤون الموظفين',
        gelistirme:'تطوير',
        sosyalmedya:'وسائل التواصل الاجتماعي',
        guvenlik:'الأمان',
        bildirim:'تنبيه',
        optimizasyon:'تحسين النظام الأساسي',
        webgelistirme:'تطوير الشبكة',
        uygulamagelistirme:'تطوير التطبيقات',
        arastirma:'ابحاث',
        magazacilik:'قطاعي',
        egitimdestek:'دعم التعليم',
        deneyimliuzmanlar:'الخبراء ذوي الخبرة',
        zamanindadestek:'في الوقت المحدد الدعم',
        uygunfiyatlar:'أسعار معقولة',
        isgelistirme:'موقف مخصص',
        musteritemsilcisi:'ممثل العملاء',
        teknikegitim:'التدريب الفني',
        harita:'خريطة',
        sirketunvani:'عنوان الشركة',
        kurulusyili:'تاريخ الافتتاح',
        adres:'عنوان',
        telefon:'هاتف',
        gsm:'هاتف محمول',
        email:'البريد الإلكتروني',
        vkn:'رقم التعريف الضريبي',
        faturaadresi:'عنوان وصول الفواتير',
        sevkiyatadresi:'عنوان الشحن',
        yetkili:'الشخص المرخص',
        firmakodu:'كود الشركة',
        firmaadi:'اسم الشركة',
        il:'المحافظة',
        vergidairesi:'مكتب الضرائب',
        sektor:'قطاع',
        notlar:'ملاحظات*',
        lisanslar:'الترخيص',
        secilenlisanslar:'تراخيص مختارة',
        kartno:'رقم البطاقة',
        kartsahibi:'حامل البطاقة',
        taksit:'توظيف',
        havale:'حوالة بريدية / تحويل أموال',
        kapidaodeme:'الدفع عند الاستلام',
        kargoucreti:'رسوم الشحن',
        ucretsiz:'مجانا',
        adet:'عددPC',
        bilgisayar:'الحاسوب',
        nakit:'نقد',
        kredikarti:'بطاقة ائتمان'
    },
    'de': {
        home:'Zuhause',
        about:'Über',
        services:'Dienstleistungen',
        references:'Verweise',
        faq:'FAQ',
        support:'Unterstützung',
        shop:'Geschäft',
        demo:'Live Demo',
        odeme:'Zahlung',
        odemeopsiyonlari:'Zahlungsmöglichkeiten',
        ay:'Monat',
        ara:'Suche',
        cart:'Einkaufswagen',
        inyourcart:'In Ihrem Warenkorb',
        addtocart:'Im Warenkorb anzeigen',
        viewincart:'Wiew in Cart',
        browseshop:'Shop durchsuchen',
        cartempty:"Sie haben keine Artikel in Ihrem Warenkorb.",
        mevcut:'Verfügbar',
        instock:'auf Lager',
        miktar:'Menge',
        fiyat:'Preis',
        tutar:'Betrag',
        sayin:'Sayın',
        fisno:'Fiş NoDokumentnummer',
        kayitno:'Registrierungsnummer',
        teslimat:'Lieferung',
        ucretsizkargo: 'Kostenloser Versand',
        kaldir:'Entfernen',
        toplamlar:'Summen',
        toplam:'Gesamt',
        iskonto:'Rabatt',
        kdv:'Vat',
        yekun:'Nettosumme',
        tamamla:'Komplett',
        tamamlandi:'Abgeschlossen',
        devam:'Fortsetzen',
        bitir:'Fertig',
        yazdir:"Drucken",
        eminmisiniz:'Bist du sicher?',
        product:'Produkt',
        relatedproducts:'Verwandte Produkte',
        peoplealsosearchforthisitems: 'Leute suchen auch nach diesen Gegenständen',
        checkout:'Auschecken',
        contacts:'Kontakt',
        whoweare:'Wer wir sind',
        quicklinks:'Quick Links',
        humanresource:'Personal',
        termsofuse:'Nutzungsbedingungen',
        privacy:'Privatsphäre',
        securepayment:'Sichere Bezahlung',
        wheretofindus:'Wo Sie uns finden',
        copyright:'Alle Rechte vorbehalten',
        ik:'Personal',
        slogan:'Schnelle und intelligente Lösungen für Ihr Unternehmen!',
        slogan1:'Ihr Unternehmen verdient bessere Software',
        slogan2:'Bessere Software fördert Umsatz und Gewinn',
        slogan3:'Ihr Unternehmen verdient bessere Software',
        slogan4:"Für Karpin müssen Benutzer keine Experten sein.",
        slogan5:"Verwalten Sie Ihr Unternehmen besser, ohne Expertenwissen zu benötigen",
        slogan6:'Wir helfen Ihnen dabei, Ihre Marketing- und Geschäftsziele zu erreichen',
        slogan7:'Verwalten Sie Ihr Geschäft durchgängig mit Karpin ERP Business Management-Lösungen.',
        ucretsizdene:'Kostenlos ausprobieren',
        simdidene:"Versuch's jetzt",
        hikayeyoksadecegercekler:'Keine Geschichten, nur Fakten',
        ayrintilisatisraporlarielinizde:'Ausführliche Verkaufsberichte zur Verfügung',
        uygulamamizisimdiucretsizdeneyin:'Kostenloser Download unserer App jetzt',
        isinizebuyumeicineniyiyardimisunun:'Bieten Sie Ihrem Unternehmen die beste Unterstützung für Wachstum',
        isinizicinhemenhareketegecin:'Ergreifen Sie jetzt Maßnahmen für Ihr Unternehmen',
        karpinicinuzmanolmayagerekyok:'Sie müssen kein Experte für Karpin sein. Mit einfachen Anleitungen ist alles einfach',
        sektorler: 'Einzelhandel und Großhandel von Unternehmen, die alle Produktionssektoren von Markt bis Restaurant, von Filialisten bis zu KMU-Unternehmen, von Bau bis Lebensmittel, von Automobil bis Möbel, von Eisen und Stahl bis Energie, von Unternehmen, die vor dem Sektor in sind das Feld Import und Export. Benutzerorientierte private Software mit Produkten für alle Größen von privaten und öffentlichen Einrichtungen in allen Dienstleistungssektoren, von Logistik, Bildung, Klassenzimmer, Hochschule, Gesundheit und Tourismus über Beratungsdienste bis hin zu allen kommerziellen Organisationen, die im Bereich Kauf und Verkauf tätig sind.',
        thanks:'Vielen Dank!',
        referans:'Referenz',
        mutlumusteriler:'Zufriedene Kunden',
        deneyim:'Erfahrung',
        bilgibirikimtecrube:'Informationen | Wissen | Erfahrung',
        uzmanekip:'Expertenteam',
        isininuzmanlari:'Profi der Arbeit',
        proje: 'Projekt',
        aciklama: 'Beschreibung',
        tarih:'Datum',
        sehir:'Stadt',
        yil:'Jahr',
        herkesicinuygunfiyatli:'Erschwinglich für alle!',
        egeruygulamadaozelisteginizvarsa:'Wenn Sie eine spezielle Anfrage in der App haben oder die Integration von E-Commerce-Website und virtuellen Märkten planen, kontaktieren Sie uns bitte!',
        sikcasorulansorular:'Häufig gestellte Fragen',
        sorularinizicevaplamakzevktir:'Es ist uns eine Freude, Ihre Fragen zu beantworten',
        tamamenduyarli:'Vollständig ansprechbar',
        daimaguncel:'Immer auf dem neuesten Stand',
        mukemmelmusteridestegi:'Hervorragende Kundenbetreuung',
        istegebagliekstrahizmetler:'Zusätzliche On-Demand-Services',
        sendekatil:'Im vergangenen Jahr haben sich 323 Unternehmen uns angeschlossen. Trete ihnen bei!',
        banaulasin:'Ruf mich an',
        bizarayalim:"Wir rufen an",
        siradanevar: 'Was kommt als nächstes in Karpin?',
        adiniz:'Name',
        telefonnumaraniz:'Dein Telefon',
        dahafazla:'Erfahren Sie mehr',
        sizinicinhepsibirarada: 'All-in-One-App für Sie',
        satisnoktasiyazilimi:'Point-of-Sale-Software',
        satisnoktasiyazilimiozellikleri:'Mit Karpin POS, das schnell und einfach zu bedienen ist und mithilfe eines Touchscreen-unterstützten Verkaufsbildschirms Zeit spart, kann der Kassierer jede Anwendung wie One-Touch-Verkauf, Retoure, Kampagne, Service und Berichterstellung über denselben Bildschirm verwalten .',
        isyonetimiyazilimi:'Unternehmensverwaltungssoftware',
        isyonetimiyazilimiozellikleri:'Kommerzielle Software, KARPİN, die alle Geschäftsprozesse, die Unternehmen benötigen, auf integrierte Weise bereitstellt, von der Produktion über den Versand, den Groß- und Einzelhandel bis hin zum Kundendienst. Es deckt alle Geschäftsprozesse ab, die ein Einzelhändler, Großhändler oder Hersteller benötigt.',
        mobilyazilim:'Mobile Software',
        mobilyazilimozellikleri:'Arbeiten über Smartphone und Tablet; Alle Transaktionen, die Sie in einer Computerumgebung auf Smartphones und Tablets ausführen können',
        baslamayahazirmisin:'Bist du bereit zu starten?',
        isinizicingelistiriyoruz:'Für Ihr Unternehmen entwickeln wir schnelle und intelligente Lösungen',
        basariniziartirmakicin:'Um Ihren Erfolg zu steigern,',
        gucluuygulamalargelistiriyoruz:'Wir entwickeln leistungsstarke Anwendungen',
        tecrubeliuzmanlarimiz:'Unsere Erfahrungsexperten',
        satispazarlama:'Sales & Marketing',
        yazilimuzmani:'Software-Experte',
        direktor:'Direktor',
        teknikuzman:'Technischer Experte',
        destekmiistiyorsunuz:'Willst du eine Unterstützung?',
        destekaraclari:'Support-Tools',
        yuklemeler:'Setups',
        veriaktarimi:'Dateien importieren',
        dokumanlar:'Unterlagen',
        videolar:'Videos',
        indir:'Download',
        izle:'Uhr',
        sayfayagit:'Zur Seite gehen',
        kolonlar:'Säulen',
        sayfaismi:'Seitenname',
        excelden:'aus Excel',
        stok:'Produkt',
        cari:'Firma',
        musteri:'Kunde',
        caribakiyesi:'Unternehmenssaldo',
        musteribakiyesi:'Kundenguthaben',
        aktarimformati:'Format importieren',
        tumalanlardoluolmali:'Alle Felder müssen ausgefüllt werden',
        sayisalalanlardoluolmali:'Wenn die numerischen Werte Null sind, müssen Sie Null (0) eingeben.',
        tarihselalanlardoluolmali:"Wenn historische Werte Null sind, sollten Sie '01 / 01/1900 'schreiben.",
        metinlerdekesmeyok:"Texte dürfen keine Apostrophe (') und Kommas (,) enthalten.",
        offlineterminalyazilimi:'Offline-Handterminal-Software',
        onlineterminalyazilimi:'Online Hand Terminal Software',
        posscreenyazilimi:'Touchscreen-Verkaufssoftware',
        posentegreyazilimi:'Pos Registrierkasse Sofortverkauf Lesen',
        kurulumdosyalari:'Dateien einrichten',
        barkodyazicisuruculeri:'Barcode-Druckertreiber',
        etiket:'Etikette',
        bayiliksozlesmesi:'Händlervertrag',
        fatura:'Rechnung',
        irsaliye:'Frachtbrief',
        siparis:'Bestellung',
        ceksenet:'Prüfen',
        kasa:'Cash Safe',
        banka:'Bank',
        perakende:'Verkauf',
        pesinsatis:'Barverkauf',
        market:'Markt',
        kredikartiislemleri:'Kreditkartentransaktionen',
        giderisleme:'Ausgaben Transaktionen',
        marketanlasmalari:'Marktvereinbarungen',
        marketkasateslimal:'Überprüfen Sie das Geld des Marktkassierers',
        etiketdizaynlari:'Etikettendesigns',
        ogrenmekistediginizhangisi:'Welches möchtest du lernen?',
        isletmenizibuyutun:'Erweitern Sie Ihr Geschäft',
        trendlereonculuketmekicinplanyapin:'Planen Sie voraus, um Fortschritte zu erzielen und den Trend voranzutreiben.',
        entegrasyondestegi:'Integrationsunterstützung',
        derinlemesinearastirmaanalizi: 'Eingehende Forschungsanalyse',
        bilimselilerleme:'Wissenschaftlicher Fortschritt',
        kullanicidostu:'Benutzerfreundlich',
        kullanicidostuslogan:'Anwendungen basieren auf einem extrem einfachen und veränderbaren Design für die Benutzerautorisierung.',
        yuksekduyarliaraclar:'Sehr reaktionsschnelle Tools',
        yuksekduyarliaraclarslogan:'Schöne Tools wie Multi-Branch, Multi-Language Use, Auto-Exchange-Währung, Autorisierung, Transaktionsverlauf, Preisverlauf und Erstellen von Verknüpfungen für häufig verwendete Transaktionen',
        mobiluyumlu:'Vollständig mobil bereit', 
        mobilslogan:'Sie können alle Aufgaben auf dem Computer im Internet, auf dem Handy oder auf dem Tablet ausführen.',
        mobilmodul:"'POS, Company, Product, Invoice, Waybill, Order, Cheque, Cash, Customer, Printer,Barcode Scanner,Reports', '1 User','1 Year License'",
        posyazilimi: 'POS-Software',
        posmodul:"'POS, Company, Product, Label, Invoice, Customer, Reports, Caller ID, Scale, Drawer and Display with work','1 User','1 Year Free Support'",
        marketyazilimi: 'Marktsoftware',
        marketmodul:"'Market, POS, Company, Product, Invoice, Wayybill, Order, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        magazayazilimi:'Einzelhandelssoftware',
        magazamodul:"'Retail, Customer, After Delivery, Company, Product, Invoice, Waybill, Order, SMS, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        lisans:'Lizenz',
        populer:'Beliebte Wahl',
        sinirlierisim:'Begrenzt',
        sektorel:'ERP, Store Market Warehouse Produktionslogistik ... in allen Branchen',
        mobilite:'Mobil, Ihr Unternehmen im Web, Mobil, Tablet',
        eticaretentegre:'Integriert in E-Commerce, n11|GG|hepsiburada|amazon|...',
        edonusumentegre:'E-Transformation, E-Rechnung | E-Archiv | E-Ledger ..',
        fikirlerinizitanimlayin:'Definieren Sie Ihre Ideen',
        fikirlerinizitanimlayinslogan:'Teilen Sie Ideen mit, wie Ihre App funktionieren soll, um Ziele zu erreichen.',
        tasarimlarustundecalisma:'Arbeiten Sie an Designs',
        tasarimlarustundecalismaslogan:'Nachdem Sie Ihren Auftrag erhalten haben, arbeiten unsere Experten an der Erstellung der endgültigen Entwürfe.',
        kodlamavegozdengecirme:'Codierung und Überprüfung',
        kodlamavegozdengecirmeslogan:'Das Endprodukt wird durch sorgfältige Codierung und Überprüfung erhalten.',
        vizyon:'Unsere Vision',
        misyonumuz:'Unsere Aufgabe',
        arkadascanlisidestek:'Neue freundliche Unterstützung',
        danismahatti:'Hotline',
        gorusbildirin:'Geben Sie Ihr Feedback',
        faturabilgileri:'Rechnungsinfo',
        telefondestegi:'Telefonischer Support',
        maildestegi:'Mail-Support',
        efaturamukellefi:'Mitglied der E-Rechnung',
        efatura:'e-Rechnung',
        efaturaaciklama:'E-Rechnung ist der Name der Rechnungen, die mit der Anwendung erstellt wurden und in denen die Rechnung elektronisch erstellt und gespeichert werden kann. E-Rechnung hat die gleichen rechtlichen Eigenschaften wie Papierrechnungen. Wenn die Systemkunden-Steuernummer E-Rechnung Zahler nicht E-Rechnung ist, erstellt E-Archive automatisch.',
        earsiv:'E-Archiv',
        earsivaciklama:'E-Archive-Rechnung ist der Name der mit der Anwendung erstellten Rechnungen, in denen die an den Empfänger der Rechnung zu sendende Kopie elektronisch oder die andere Kopie elektronisch gespeichert werden kann. Die Übermittlung der E-Archiv-Rechnung, bei der es sich um eine Kopie handelt, an den Empfänger der Rechnung kann per E-Mail, SMS erfolgen. Optional kann der Steuerzahler entscheiden, ob die Rechnung in eine Papierrechnung umgewandelt werden soll.',
        eirsaliye:'E-Frachtbrief',
        eirsaliyeaciklama:'Der elektronische Frachtbrief hat die gleichen rechtlichen Eigenschaften wie der Papierfrachtbrief und wird hauptsächlich anstelle des Frachtbriefs verwendet und an die empfangende Partei weitergeleitet. Die Papierausgabe des elektronischen Frachtbriefs wird als Frachtbrief im Fahrzeug aufbewahrt. Um e-Waybill zwischen Unternehmen zu arrangieren, müssen sowohl der Käufer als auch der Verkäufer in der e-Waybill-Anwendung registriert sein.',
        zamantasarrufu:'Zeitersparnis',
        zamantasarrufuaciklama:'Sie können Ihre Rechnung mit einem Klick an Ihren Kunden liefern und den Inkassoprozess beschleunigen. Dank Ihrer elektronisch übermittelten Rechnungen können Sie Prozesse wie den Abgleich beenden und Ihre Forderungen schneller erhalten.',
        gecmisverilereulasimkolayligi:'Einfacher Zugriff auf historische Daten',
        gecmisverilereulasimkolayligiaciklama:'Mit e-Invoice werden Ihre Rechnungen elektronisch gespeichert. Mit elektronischer Signatur und elektronischer Archivierung können Sie Daten sichern und einfach auf Ihre früheren Aufzeichnungen zugreifen. Sie werden von der Last der Benutzerfehler befreit.',
        kagitsaklamasikintisininsonaermesi:'Problem mit der Papierlagerung',
        kagitsaklamasikintisininsonaermesiaciklama:'Sie können auf Ihre früheren Rechnungen zugreifen, ohne in den Dateien nach Rechnungen suchen zu müssen. So können Sie den Verlust von Rechnungen verhindern. Sie können frühere Rechnungsverluste vermeiden und Probleme bei der physischen Archivierung und Papierlagerung beseitigen.',
        maliyetavantaji:'Kostenvorteil',
        maliyetavantajiaciklama:'Sie entfallen die Kosten für den Druck der Rechnungen und die Bezahlung der Druckereien, Sie vermeiden die Unregelmäßigkeitsstrafen, die sich aus der Verpflichtung zur Einhaltung der Gesetze ergeben, und Sie vermeiden die Kosten für den Versand von Rechnungen. So erreichen Sie Ihre Kunden schneller und beschleunigen Ihren Abholprozess.',
        gercekzamanliisanalizleri:'Erhalten Sie Geschäftsanalysen in Echtzeit',
        kullanicidostutasarimlar:'Benutzerfreundliche Designs erstellen',
        gelecegehazirtaleplereduyarli:'Bereit für die Zukunft sein und auf Anforderungen reagieren',
        dinleyenogrenen:'Es soll zuhören, lernen, sich entwickeln, sich anpassen',
        isimizibuyutme:'Wir bemühen uns, alle Ihre Bedürfnisse zu erfüllen, um unser Geschäft auszubauen und Markenbekanntheit zu schaffen.',
        eniyiyolisibuyutme:'Der beste Weg, um das Geschäft auszubauen',
        herguncalisiyoruz:'Wir ziehen gleichgesinnte und talentierte Mitglieder an, um Ihnen dabei zu helfen, ein stärkeres Geschäft aufzubauen und jeden Tag energisch höhere Ziele zu erreichen.',
        fikirleribirlestir:'Ideen verbinden',
        planyap:'Zeitplan erstellen',
        zamaniyonet:'Zeit verwalten',
        mobilcalis:'Mobil arbeiten',
        dahayuksekbuyumeicingelistirildi:'Entwickelt für ein höheres Geschäftswachstum',
        guvenilirhizmetler:'Zuverlässige Dienstleistungen',
        kapsamlidokumanlar:'Umfangreiche Dokumente',
        musteridestegi:'Kunden hilfe',
        garantilibuyume:'Garantiertes Wachstum',
        entegrasyon:'Integration',
        musteriyonetimi:'Mitarbeiterführung',
        gelistirme:'Entwicklung',
        sosyalmedya:'Sozialen Medien',
        guvenlik:'Sicherheit',
        bildirim:'Benachrichtigung',
        optimizasyon:'Plattformoptimierung',
        webgelistirme:'Web Entwicklung',
        uygulamagelistirme:'Anwendungsentwicklung',
        arastirma:'Forschung',
        magazacilik:'Verkauf',
        egitimdestek:'Bildungsunterstützung',
        deneyimliuzmanlar:'Erfahrene Experten',
        zamanindadestek:'Pünktlicher Support',
        uygunfiyatlar:'Bezahlbare Preise',
        isgelistirme:'Engagierte Haltung',
        musteritemsilcisi:'Kunden Vertreter',
        teknikegitim:'Technisches Training',
        harita:'Karte',
        sirketunvani:'Unternehmen Titel',
        kurulusyili:'Gründungsjahr',
        adres:'Adresse',
        telefon:'Telefon',
        gsm:'Gsm',
        email:'E-Mail',
        vkn:'Steueridentifikationsnummer',
        faturaadresi:'Rechnungsadresse',
        sevkiyatadresi:'Lieferanschrift',
        yetkili:'Berechtigte Person',
        firmakodu:'Buchungskreis',
        firmaadi:'Name der Firma',
        il:'Provinz',
        vergidairesi:'Finanzamt',
        sektor:'Sektor',
        notlar:'Anmerkungen*',
        lisanslar:'Lizenzierung',
        secilenlisanslar:'Ausgewählte Lizenzen',
        kartno:'Kartennummer',
        kartsahibi:'Kartenhalter',
        taksit:'Rate',
        havale:'Zahlungsanweisung / EFT',
        kapidaodeme:'Barzahlung bei Lieferung',
        kargoucreti:'Versandkosten',
        ucretsiz:'Kostenlos',
        adet:'Stück',
        bilgisayar:'Computer',
        nakit:'Bargeld',
        kredikarti:'Kredit Karte'
    },
    'ru': {
        home:'Дом',
        about:'Около',
        services:'Сервисы',
        references:'Ссылки',
        faq:'Вопросы-Ответы',
        support:'Служба поддержки',
        shop:'Магазин',
        demo:'Жить демонстрация',
        odeme:'Оплата',
        odemeopsiyonlari:'Варианты оплаты',
        ay:'Месяц',
        ara:'поиск',
        cart:'Корзина',
        inyourcart:'В вашей корзине',
        addtocart:'добавить в корзину',
        viewincart:'Посмотреть в корзине',
        browseshop:'Обзор магазина',
        cartempty:"У вас нет товаров в корзине.",
        mevcut:'Доступный',
        instock:'в наличии',
        miktar:'Кол-во',
        fiyat:'цена',
        tutar:'количество',
        sayin:'дорогой',
        fisno:'Номер документа',
        kayitno:'рег номер',
        teslimat:'Доставка',
        ucretsizkargo: 'Бесплатная доставка',
        kaldir:'удалять',
        toplamlar:'общие данные',
        toplam:'Общее количество',
        iskonto:'скидка',
        kdv:'НДС',
        yekun:'Всего нетто',
        tamamla:'полный',
        tamamlandi:'Завершенный',
        devam:'Продолжить',
        bitir:'Конец',
        yazdir:"Распечатать",
        eminmisiniz:'Ты уверен?',
        product:'продукт',
        relatedproducts:'сопутствующие товары',
        peoplealsosearchforthisitems: 'люди также ищут этот товар',
        checkout:'проверять, выписываться',
        contacts:'контакт',
        whoweare:'Кто мы',
        quicklinks:'Быстрые ссылки',
        humanresource:'отдел кадров',
        termsofuse:'Условия эксплуатации',
        privacy:'Конфиденциальность',
        securepayment:'Безопасная оплата',
        wheretofindus:'где нас найти',
        copyright:'Все права защищены',
        ik:'отдел кадров',
        slogan:'Быстрые и умные решения для вашего бизнеса!',
        slogan1:'Ваш бизнес заслуживает лучшего программного обеспечения',
        slogan2:'Лучшее программное обеспечение способствует продажам и прибыли',
        slogan3:'Ваш бизнес заслуживает лучшего программного обеспечения',
        slogan4:"Карпин не требует, чтобы пользователи были экспертами.",
        slogan5:"Управляйте своим бизнесом лучше, не требуя экспертных знаний",
        slogan6:'Мы поможем вам достичь ваших маркетинговых и деловых целей',
        slogan7:'Комплексное управление своим бизнесом с помощью решений Karpin ERP Business Management.',
        ucretsizdene:'Попробовать бесплатно',
        simdidene:'Попробуй сейчас',
        hikayeyoksadecegercekler:'Нет историй, только факты',
        ayrintilisatisraporlarielinizde:'Подробные отчеты о продажах в вашем распоряжении',
        uygulamamizisimdiucretsizdeneyin:'Бесплатно скачать наше приложение сейчас',
        isinizebuyumeicineniyiyardimisunun:'Предложите вашему бизнесу лучшую помощь для роста',
        isinizicinhemenhareketegecin:'Действуй для своего бизнеса сейчас',
        karpinicinuzmanolmayagerekyok:'Не нужно быть экспертом для Карпина, все просто с простыми руководствами',
        sektorler:"Розничная и оптовая торговля от компаний, занимающих все производственные секторы: от рынка до ресторана, от сетевых магазинов до предприятий малого и среднего бизнеса, от строительства до производства продуктов питания, от автомобильной промышленности до мебели, от железа и стали до энергетики, от компаний, которые находятся перед сектором в поле импорта и экспорта. Ориентированное на пользователя частное программное обеспечение с продуктами для всех размеров частных и государственных учреждений во всех секторах услуг: от логистики, образования, учебных заведений, колледжей, здравоохранения и туризма до консультационных услуг для всех коммерческих организаций, работающих в сфере купли-продажи.",
        thanks:'Благодарность!',
        referans:'Ссылка',
        mutlumusteriler:'Счастливые клиенты',
        deneyim:'Опыт',
        bilgibirikimtecrube:'Информация | Знание | Опыт',
        uzmanekip:'Экспертная группа',
        isininuzmanlari:'Профессионал Работы',
        proje: 'проект',
        aciklama: 'Описание',
        tarih:'Дата',
        sehir:'город',
        yil:'Год',
        herkesicinuygunfiyatli:'Доступно для всех!',
        egeruygulamadaozelisteginizvarsa:'Если у вас есть специальный запрос в приложении или вы планируете интегрировать сайт электронной коммерции и виртуальные рынки, пожалуйста, свяжитесь с нами!',
        sikcasorulansorular:'Часто задаваемые вопросы',
        sorularinizicevaplamakzevktir:'Мы рады ответить на любые ваши вопросы',
        tamamenduyarli:'Полностью отзывчивый',
        daimaguncel:'Всегда в курсе',
        mukemmelmusteridestegi:'Отличная поддержка клиентов',
        istegebagliekstrahizmetler:'Дополнительные услуги по запросу',
        sendekatil:'В прошлом году к нам присоединились 323 предприятия. Присоединяйся к ним!',
        banaulasin:'Позвони мне',
        bizarayalim:"Мы позвоним",
        siradanevar: 'Что дальше в Карпине?',
        adiniz:'имя',
        telefonnumaraniz:'Ваш телефон',
        dahafazla: 'Учить больше', 
        sizinicinhepsibirarada: 'Многофункциональное адаптивное приложение для вас',
        satisnoktasiyazilimi:'Программное обеспечение Point Of Sale',
        satisnoktasiyazilimiozellikleri:'С Karpin POS, который является быстрым и простым в использовании и экономит время с помощью экрана продаж с сенсорным экраном, кассир может управлять каждым приложением, таким как продажи, возврат, кампания, обслуживание и отчетность в одно касание с одного экрана. ,',
        isyonetimiyazilimi:'Программное обеспечение для управления бизнесом',
        isyonetimiyazilimiozellikleri:'Коммерческое программное обеспечение, KARPİN, которое интегрирует все бизнес-процессы, необходимые бизнесу, от производства до доставки, оптовых и розничных продаж до послепродажного обслуживания; Он охватывает все бизнес-процессы, которые потребуются компании розничной торговли, оптовика или производителя.',
        mobilyazilim:'Мобильное ПО',
        mobilyazilimozellikleri:'Работа через смартфон и планшет; Все транзакции, которые вы можете делать в компьютерной среде на смартфонах и планшетах',
        baslamayahazirmisin:'Вы готовы начать?',
        isinizicingelistiriyoruz:'Для вашего бизнеса мы разрабатываем быстрые и умные решения',
        basariniziartirmakicin:'Чтобы увеличить ваш успех,',
        gucluuygulamalargelistiriyoruz:'мы разрабатываем мощные приложения',
        tecrubeliuzmanlarimiz:'Наш опыт экспертов',
        satispazarlama:'Продажи и Маркетинг',
        yazilimuzmani:'Эксперт по программному обеспечению',
        direktor:'директор',
        teknikuzman:'Техник Эксперт',
        destekmiistiyorsunuz:'Хотите поддержку?',
        destekaraclari:'Инструменты поддержки',
        yuklemeler:'Setups',
        veriaktarimi:'Импорт файлов',
        dokumanlar:'документы',
        videolar:'Видео',
        indir:'Скачать',
        izle:'часы',
        sayfayagit:'Перейти на страницу',
        kolonlar:'Колонны',
        sayfaismi:'Название страницы',
        excelden:'из Excel',
        stok:'Продукт',
        cari:'Компания',
        musteri:'Покупатель',
        caribakiyesi:'Баланс Компании',
        musteribakiyesi:'Баланс клиента',
        aktarimformati:'Формат импорта',
        tumalanlardoluolmali:'Все поля должны быть заполнены',
        sayisalalanlardoluolmali:'Если числовые значения равны нулю, вы должны ввести ноль (0).',
        tarihselalanlardoluolmali:"Если исторические значения равны нулю, вы должны написать «01.01.1900»",
        metinlerdekesmeyok:"Тексты не должны содержать апострофы (') и запятые (,)",
        offlineterminalyazilimi:'Автономный ручной терминал',
        onlineterminalyazilimi:'Программное обеспечение для онлайн-терминала',
        posscreenyazilimi:'Программное обеспечение для продажи с сенсорным экраном',
        posentegreyazilimi:'Почтовый кассовый аппарат Мгновенная продажа',
        kurulumdosyalari:'Установочные файлы',
        barkodyazicisuruculeri:'Драйверы для принтеров штрих-кодов',
        etiket:'метка',
        bayiliksozlesmesi:'Дилерский договор',
        fatura:'Выставленный счет',
        irsaliye:'накладная',
        siparis:'порядок',
        ceksenet:'Проверить',
        kasa:'Сейф наличными',
        banka:'Банка',
        perakende:'RРозничная торговля',
        pesinsatis:'Продажа за наличные',
        market:'рынок',
        kredikartiislemleri:'Операции с кредитными картами',
        giderisleme:'Расходы Транзакции',
        marketanlasmalari:'Рыночные соглашения',
        marketkasateslimal:'Чек Маркет Кассир Деньги',
        etiketdizaynlari:'Дизайн этикеток',
        ogrenmekistediginizhangisi:'Какой из них вы хотите узнать?',
        isletmenizibuyutun:'Расширьте свой бизнес',
        trendlereonculuketmekicinplanyapin:'Планируйте заранее, чтобы добиться прогресса и возглавить тенденцию.',
        entegrasyondestegi:'Интеграционная поддержка',
        derinlemesinearastirmaanalizi: 'Углубленный анализ исследований',
        bilimselilerleme:'Научный прогресс',
        kullanicidostu:'Дружественный интерфейс',
        kullanicidostuslogan:'Приложения построены на чрезвычайно простом и изменяемом дизайне для авторизации пользователей.',
        yuksekduyarliaraclar:'Очень отзывчивые инструменты',
        yuksekduyarliaraclarslogan:'Красивые инструменты, такие как многоотраслевое, многоязычное использование, валюта автообмена, авторизация, история транзакций, история цен и создание ярлыков для часто используемых транзакций',
        mobiluyumlu:'Полностью мобильный, готовый', 
        mobilslogan:'Вы можете делать все, что вы делаете на компьютере, в Интернете, на мобильном телефоне, на планшете.',
        mobilmodul:"'POS, Company, Product, Invoice, Waybill, Order, Cheque, Cash, Customer, Printer,Barcode Scanner,Reports', '1 User','1 Year License'",
        posyazilimi: 'ПО для POS',
        posmodul:"'POS, Company, Product, Label, Invoice, Customer, Reports, Caller ID, Scale, Drawer and Display with work','1 User','1 Year Free Support'",
        marketyazilimi: 'Маркет Программное обеспечение',
        marketmodul:"'Market, POS, Company, Product, Invoice, Wayybill, Order, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        magazayazilimi:'Программное обеспечение для розничной торговли',
        magazamodul:"'Retail, Customer, After Delivery, Company, Product, Invoice, Waybill, Order, SMS, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        lisans:'Лицензия',
        populer:'Популярный выбор',
        sinirlierisim:'Ограниченное',
        sektorel:'ERP, Store Market Склад Производство Логистика ... во всех секторах',
        mobilite:'Мобильный, Ваш бизнес в Интернете, Мобильный, Планшетный',
        eticaretentegre:'Интегрированный с электронной коммерцией, n11|GG|hepsiburada|amazon|...',
        edonusumentegre:'Электронная трансформация, электронный счет-фактура | Электронный архив | Электронная книга ..',
        fikirlerinizitanimlayin:'Определите ваши идеи',
        fikirlerinizitanimlayinslogan:'Поделитесь идеями о том, как вы хотите, чтобы ваше приложение работало для достижения целей.',
        tasarimlarustundecalisma:'Работа над дизайном',
        tasarimlarustundecalismaslogan:'После получения вашего резюме наши специалисты работают над созданием окончательных дизайнов.',
        kodlamavegozdengecirme:'Кодирование и обзор',
        kodlamavegozdengecirmeslogan:'Конечный продукт получается из тщательного кодирования и обзора.',
        vizyon:'Наше видение',
        misyonumuz:'Наша миссия',
        arkadascanlisidestek:'Новая дружественная поддержка',
        danismahatti:'Горячая линия',
        gorusbildirin:'Оставьте свой отзыв',
        faturabilgileri:'информация о счете',
        telefondestegi:'Поддержка по телефону',
        maildestegi:'Поддержка почты',
        efaturamukellefi:'Участник электронного счета',
        efatura:'е-фактура',
        efaturaaciklama:'e-Invoice - это имя, присваиваемое счетам, созданным с помощью приложения, в котором счет можно создавать и хранить в электронном виде. Электронный счет имеет те же юридические качества, что и бумажные счета. Если системный номер налогоплательщика-плательщика не является электронным счетом, автоматически создается электронный архив.',
        earsiv:'E-Архив',
        earsivaciklama:'Счет в электронном архиве - это имя, присваиваемое счетам, созданным вместе с приложением, в котором копия, отправляемая получателю счета, может храниться в электронном виде или другая копия может храниться в электронном виде. Передача счета-фактуры электронного архива, являющегося копией, получателю счета-фактуры может быть осуществлена посредством электронной почты, SMS-сообщений и, при необходимости, налогоплательщик может решить, следует ли преобразовать счет-фактуру в бумажный счет-фактуру.',
        eirsaliye:'е-накладная',
        eirsaliyeaciklama:'Электронная накладная обладает теми же правовыми качествами, что и бумажная накладная, и будет использоваться в основном вместо накладной и будет доставлена принимающей стороне. Бумажная продукция электронной накладной будет храниться в транспортном средстве как накладная. Для организации электронной отправки между компаниями покупатель и продавец должны быть зарегистрированы в приложении электронной отправки.',
        zamantasarrufu:'Экономия времени',
        zamantasarrufuaciklama:'Вы можете отправить свой счет клиенту одним щелчком мыши и ускорить процесс сбора. Благодаря вашим счетам, переданным в электронном виде, вы можете завершить такие процессы, как выверка, и быстрее получить дебиторскую задолженность.',
        gecmisverilereulasimkolayligi:'Легкий доступ к историческим данным',
        gecmisverilereulasimkolayligiaciklama:'С электронной накладной ваши счета хранятся в электронном виде. С электронной подписью и электронным архивированием вы можете защитить данные и легко получить доступ к своим прошлым записям Вы избавляетесь от бремени ошибок пользователя.',
        kagitsaklamasikintisininsonaermesi:'Проблема с хранением бумаги',
        kagitsaklamasikintisininsonaermesiaciklama:'Вы можете получить доступ к своим прошлым счетам без необходимости искать счета среди файлов; Таким образом, вы можете предотвратить потерю счетов. Вы можете предотвратить потерю счетов в прошлом и избавиться от физических проблем с архивированием и хранением бумаги.',
        maliyetavantaji:'Стоимость Преимущество',
        maliyetavantajiaciklama:'Вы избавляетесь от затрат на печать счетов и оплату печатных машин, избегаете штрафов за несоблюдение правил, возникающих в связи с обязательством соблюдать законодательство, и избегаете затрат на отправку счетов. Таким образом, вы быстрее достигаете своих клиентов и ускоряете процесс сбора.',
        gercekzamanliisanalizleri:'Получать бизнес-аналитику в реальном времени',
        kullanicidostutasarimlar:'Создание удобного дизайна',
        gelecegehazirtaleplereduyarli:'Быть готовым к будущему и реагировать на требования',
        dinleyenogrenen:'Это значит слушать, учиться, развиваться, приспосабливаться',
        isimizibuyutme:'Мы стремимся удовлетворить все ваши потребности для развития нашего бизнеса и повышения узнаваемости бренда.',
        eniyiyolisibuyutme:'Лучший способ развивать бизнес',
        herguncalisiyoruz:'Мы привлекаем единомышленников и талантливых членов, чтобы помочь вам развить более сильный бизнес для стремительного ежедневного достижения более высоких целей.',
        fikirleribirlestir:'Соедините идеи',
        planyap:'Составить расписание',
        zamaniyonet:'Управлять временем',
        mobilcalis:'Рабочий Мобильный',
        dahayuksekbuyumeicingelistirildi:'Разработано для более быстрого роста бизнеса',
        guvenilirhizmetler:'Надежные Услуги',
        kapsamlidokumanlar:'Обширные документы',
        musteridestegi:'Поддержка клиентов',
        garantilibuyume:'Гарантированный рост',
        entegrasyon:'интеграция',
        musteriyonetimi:'Управление персоналом',
        gelistirme:'развитие',
        sosyalmedya:'Социальные сети',
        guvenlik:'Безопасность',
        bildirim:'уведомление',
        optimizasyon:'Оптимизация платформы',
        webgelistirme:'Веб-разработка',
        uygulamagelistirme:'Разработка приложения',
        arastirma:'Исследование',
        magazacilik:'Розничная торговля',
        egitimdestek:'Поддержка образования',
        deneyimliuzmanlar:'Опытные Эксперты',
        zamanindadestek:'Поддержка в срок',
        uygunfiyatlar:'Доступные цены',
        isgelistirme:'Выделенное отношение',
        musteritemsilcisi:'Представитель клиента',
        teknikegitim:'Техническое обучение',
        harita:'карта',
        sirketunvani:'Название компании',
        kurulusyili:'Год основания',
        adres:'Адрес',
        telefon:'телефон',
        gsm:'Gsm',
        email:'E-Mail',
        vkn:'Идентификационный налоговый номер',
        faturaadresi:'Платежный адрес',
        sevkiyatadresi:'Адреса доставки',
        yetkili:'Уполномоченное лицо',
        firmakodu:'Код компании',
        firmaadi:'Название компании',
        il:'Провинция',
        vergidairesi:'Налоговая служба',
        sektor:'сектор',
        notlar:'Примечания*',
        lisanslar:'Лицензирование',
        secilenlisanslar:'Избранные лицензии',
        kartno:'Номер карты',
        kartsahibi:'Держатель карты',
        taksit:'взнос',
        havale:'Денежный перевод / EFT',
        kapidaodeme:'Оплата при доставке',
        kargoucreti:'Стоимость доставки',
        ucretsiz:'свободно',
        adet:'номер',
        bilgisayar:'компьютер',
        nakit:'наличные деньги',
        kredikarti:'Кредитная карта'
    },
    'cn': {
        home:'主頁',
        about:'關於',
        services:'服務',
        references:'參考文獻',
        faq:'常問問題',
        support:'支持',
        shop:'店',
        demo:'现场演示',
        odeme:'付款',
        odemeopsiyonlari:'付款方式',
        ay:'月',
        ara:'搜索',
        cart:'购物车',
        inyourcart:'在您的购物车中',
        addtocart:'添加到购物车',
        viewincart:'在购物车中查看',
        browseshop:'浏览商店',
        cartempty:"您的购物车中没有任何物品。",
        mevcut:'可用的',
        instock:'有现货',
        miktar:'数量',
        fiyat:'价钱',
        tutar:'量',
        sayin:'亲',
        fisno:'文件编号',
        kayitno:'注册号',
        teslimat:'交货',
        ucretsizkargo: '免费送货',
        kaldir:'去掉',
        toplamlar:'合计',
        toplam:'总',
        iskonto:'折扣',
        kdv:'大桶',
        yekun:'净总值',
        tamamla:'完成',
        tamamlandi:'已完成',
        devam:'继续',
        bitir:'完',
        yazdir:"打印",
        eminmisiniz:'你确定吗？',
        product:'产品',
        relatedproducts:'相关产品',
        peoplealsosearchforthisitems: '人们也在搜索此商品',
        checkout:'查看',
        contacts:'聯繫',
        whoweare:'我們是誰',
        quicklinks:'快速鏈接',
        humanresource:'人力資源',
        termsofuse:'使用條款',
        privacy:'隱私',
        securepayment:'安全付款',
        wheretofindus:'在哪裡找到我們',
        copyright:'版權所有',
        ik:'人力資源',
        slogan:'為您的企業提供快速，智能的解決方案！',
        slogan1:'您的企業應該擁有更好的軟件',
        slogan2:'更好的軟件可以促進銷售和利潤',
        slogan3:'您的企業應該擁有更好的軟件',
        slogan4:"Karpin不需要用戶成為專家。",
        slogan5:"無需專業知識即可更好地管理您的業務",
        slogan6:'我們將幫助您實現營銷和業務目標',
        slogan7:'使用Karpin ERP業務管理解決方案端到端地管理您的業務。',
        ucretsizdene:'免費試用',
        simdidene:'現在試試',
        hikayeyoksadecegercekler:'沒有故事，只有事實',
        ayrintilisatisraporlarielinizde:'深入的銷售報告隨時可用',
        uygulamamizisimdiucretsizdeneyin:'現在免費下載我們的應用程序',
        isinizebuyumeicineniyiyardimisunun:'為您的業務提供最佳的增長幫助',
        isinizicinhemenhareketegecin:'立即為您的業務採取行動',
        karpinicinuzmanolmayagerekyok:'無需成為Karpin的專家，簡單的指南即可輕鬆完成一切',
        sektorler:"零售和批發的公司涉及從市場到餐廳的所有生產領域，從連鎖商店到中小型企業，從建築到食品，從汽車到家具，從鋼鐵到能源，再到在該領域處於領先地位的公司 進出口領域。 面向用戶的私有軟件，其產品適用於從物流，教育，教室，學院，衛生和旅遊業到諮詢服務的所有服務領域的各種規模的私有和公共機構，以及在採購和銷售領域運營的所有商業組織。",
        thanks:'謝謝！',
        referans:'參考',
        mutlumusteriler:'客戶滿意',
        deneyim:'經驗',
        bilgibirikimtecrube:'信息| 知識| 經驗',
        uzmanekip:'專家團隊',
        isininuzmanlari:'工作專業人員',
        proje: '項目',
        aciklama: '描述',
        tarih:'日期',
        sehir:'市',
        yil:'年',
        herkesicinuygunfiyatli:'每個人都負擔得起！',
        egeruygulamadaozelisteginizvarsa:'如果您對應用程序有特殊要求，或計劃將電子商務網站和虛擬市場集成在一起，請與我們聯繫！',
        sikcasorulansorular:'經常問的問題',
        sorularinizicevaplamakzevktir:'很高興回答您的任何問題',
        tamamenduyarli:'完全響應',
        daimaguncel:'始終保持最新',
        mukemmelmusteridestegi:'出色的客戶支持',
        istegebagliekstrahizmetler:'按需額外服務',
        sendekatil:'去年，有323家企業加入了我們。 加入他們！',
        banaulasin:'打給我',
        bizarayalim:"我們會打電話",
        siradanevar: 'Karpin接下來會做什麼？',
        adiniz:'名稱',
        telefonnumaraniz:'您的手機',
        dahafazla:'學到更多',
        sizinicinhepsibirarada: '一應俱全的響應式應用',
        satisnoktasiyazilimi:'銷售點軟件',
        satisnoktasiyazilimiozellikleri:'借助Karpin POS，它快速且易於使用，並藉助支持觸摸屏的銷售屏幕來節省時間，收銀員能夠在同一屏幕上管理每個應用程序，如一鍵式銷售，退貨，活動，服務和報告 。',
        isyonetimiyazilimi:'商業管理軟件',
        isyonetimiyazilimiozellikleri:'商業軟件KARPİN，它以集成的方式提供企業所需的所有業務流程，從生產到運輸，批發和零售再到售後服務； 它涵蓋了零售商，批發商或製造商公司所需的所有業務流程。',
        mobilyazilim:'手機軟件',
        mobilyazilimozellikleri:'通過智能手機和平板電腦工作； 您可以在智能手機和平板電腦的計算機環境中進行的所有交易',
        baslamayahazirmisin:'您準備好開始了嗎？',
        isinizicingelistiriyoruz:'為了您的業務，我們開發快速，智能的解決方案',
        basariniziartirmakicin:'為了增加您的成功，',
        gucluuygulamalargelistiriyoruz:'我們開發功能強大的應用程序',
        tecrubeliuzmanlarimiz:'我們的經驗專家',
        satispazarlama:'銷售與市場營銷',
        yazilimuzmani:'軟件專家',
        direktor:'導向器',
        teknikuzman:'技術專家',
        destekmiistiyorsunuz:'需要支持嗎？',
        destekaraclari:'支持工具',
        yuklemeler:'設定',
        veriaktarimi:'導入文件',
        dokumanlar:'文件資料',
        videolar:'影片',
        indir:'下載',
        izle:'看',
        sayfayagit:'轉到頁面',
        kolonlar:'列',
        sayfaismi:'頁面名稱',
        excelden:'從Excel',
        stok:'產品',
        cari:'公司',
        musteri:'顧客',
        caribakiyesi:'公司餘額',
        musteribakiyesi:'客戶餘額',
        aktarimformati:'導入格式',
        tumalanlardoluolmali:'必須填寫所有字段',
        sayisalalanlardoluolmali:'如果數值為空，則必須輸入零（0）。',
        tarihselalanlardoluolmali:"如果“歷史值”為Null，則應輸入“ 01 / 01/1900”",
        metinlerdekesmeyok:"文字不得包含撇號（'）和逗號（，）",
        offlineterminalyazilimi:'離線手持終端軟件',
        onlineterminalyazilimi:'在線手動終端軟件',
        posscreenyazilimi:'觸摸屏銷售軟件',
        posentegreyazilimi:'Pos收銀機即時銷售閱讀',
        kurulumdosyalari:'安裝文件',
        barkodyazicisuruculeri:'條形碼打印機驅動程序',
        etiket:'標籤',
        bayiliksozlesmesi:'經銷商協議',
        fatura:'發票',
        irsaliye:'運單',
        siparis:'訂購',
        ceksenet:'校驗',
        kasa:'現金保險櫃',
        banka:'銀行',
        perakende:'零售',
        pesinsatis:'現金銷售',
        market:'市場',
        kredikartiislemleri:'信用卡交易',
        giderisleme:'費用交易',
        marketanlasmalari:'市場協議',
        marketkasateslimal:'檢查市場收銀員錢',
        etiketdizaynlari:'標籤設計',
        ogrenmekistediginizhangisi:'您想學習哪一個？',
        isletmenizibuyutun:'拓展業務',
        trendlereonculuketmekicinplanyapin:'提前計劃以取得進步並引領潮流。',
        entegrasyondestegi:'整合支援',
        derinlemesinearastirmaanalizi: '深入研究分析',
        bilimselilerleme:'科學進步',
        kullanicidostu:'方便使用的',
        kullanicidostuslogan:'應用程序基於極其簡單且可更改的設計來構建，以進行用戶授權。',
        yuksekduyarliaraclar:'高響應性工具',
        yuksekduyarliaraclarslogan:'漂亮的工具，例如多分支，多語言使用，自動交換貨幣，授權，交易歷史記錄，價格歷史記錄以及為常用交易創建快捷方式',
        mobiluyumlu:'完全移動就緒', 
        mobilslogan:'您可以在網絡，移動設備，平板電腦上的計算機上完成所有操作。',
        mobilmodul:"'POS, Company, Product, Invoice, Waybill, Order, Cheque, Cash, Customer, Printer,Barcode Scanner,Reports', '1 User','1 Year License'",
        posyazilimi: 'POS軟件',
        posmodul:"'POS, Company, Product, Label, Invoice, Customer, Reports, Caller ID, Scale, Drawer and Display with work','1 User','1 Year Free Support'",
        marketyazilimi: '市場軟件',
        marketmodul:"'Market, POS, Company, Product, Invoice, Wayybill, Order, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        magazayazilimi:'零售軟件',
        magazamodul:"'Retail, Customer, After Delivery, Company, Product, Invoice, Waybill, Order, SMS, Barcode, Label, Counting, Reports','1 User','1 Year Free Support'",
        lisans:'執照',
        populer:'熱門選擇',
        sinirlierisim:'有限',
        sektorel:'ERP，商店市場倉庫生產物流...在所有領域',
        mobilite:'移動設備，您的網絡業務，移動設備，平板電腦',
        eticaretentegre:'n11|GG|hepsiburada|amazon|...與電子商務集成',
        edonusumentegre:'電子轉換，電子發票|電子存檔|電子賬本..',
        fikirlerinizitanimlayin:'定義你的想法',
        fikirlerinizitanimlayinslogan:'分享有關您希望應用程序如何實現目標的想法。',
        tasarimlarustundecalisma:'設計工作',
        tasarimlarustundecalismaslogan:'簡要介紹您的意見後，我們的專家將致力於最終設計。',
        kodlamavegozdengecirme:'編碼和審查',
        kodlamavegozdengecirmeslogan:'最終產品是通過精心編碼和審核而獲得的。',
        vizyon:'我們的願景',
        misyonumuz:'我們的任務',
        arkadascanlisidestek:'新的友好支持',
        danismahatti:'熱線電話',
        gorusbildirin:'提供您的反饋',
        faturabilgileri:'發票信息',
        telefondestegi:'電話支持',
        maildestegi:'郵件支持',
        efaturamukellefi:'電子發票會員',
        efatura:'电子发票',
        efaturaaciklama:'电子发票是使用应用程序创建的发票的名称，可以在该应用程序中以电子方式创建和存储发票。 电子发票与纸质发票具有相同的法律素质。 如果系统客户税号电子发票付款人不是电子发票，则电子归档将自动创建。',
        earsiv:'E-存档',
        earsivaciklama:'e-存档发票是使用该应用程序创建的发票的名称，发送给发票收件人的副本可以通过电子方式存储，或者其他副本可以通过电子方式存储。 可以通过电子邮件，短信将电子存档发票（作为副本）传输到发票的收件人，并且纳税人可以选择是否将发票转换为纸质发票。',
        eirsaliye:'电子运单',
        eirsaliyeaciklama:'电子运单与纸质运单具有相同的法律质量，将主要用于代替货运单，并将其交付给接收方。 电子运单的纸张输出将作为运单保存在车辆中。 为了安排公司之间的电子发送，买卖双方都必须在电子发送申请中进行注册。',
        zamantasarrufu:'节省时间',
        zamantasarrufuaciklama:'您可以通过单击将发票发送给客户，并加快收款过程。 由于您的发票是通过电子方式传输的，因此您可以结束对帐等流程并更快地获取应收款。',
        gecmisverilereulasimkolayligi:'轻松访问历史数据',
        gecmisverilereulasimkolayligiaciklama:'使用电子发票，您的发票将以电子方式存储。 通过电子签名和电子存档，您可以保护数据并轻松访问您的过去记录。 您摆脱了用户错误的负担。',
        kagitsaklamasikintisininsonaermesi:'缺纸问题',
        kagitsaklamasikintisininsonaermesiaciklama:'您可以访问过去的发票，而不必在文件中搜索发票； 因此，您可以防止发票丢失。 您可以防止过去的发票丢失，并避免物理存档和纸张存储问题。',
        maliyetavantaji:'成本优势',
        maliyetavantajiaciklama:'您省去了打印发票和支付印刷厂的费用，避免了因遵守法规的义务而产生的违规罚款，并且避免了发送发票的费用。 因此，您可以更快地与客户联系并加快收集过程。',
        gercekzamanliisanalizleri:'接收實時業務分析',
        kullanicidostutasarimlar:'製作人性化的設計',
        gelecegehazirtaleplereduyarli:'為未來做好準備並響應需求',
        dinleyenogrenen:'是聆聽，學習，發展，適應',
        isimizibuyutme:'我們努力滿足您的所有需求，以發展我們的業務並樹立品牌知名度。',
        eniyiyolisibuyutme:'拓展業務的最佳途徑',
        herguncalisiyoruz:'我們會召集志趣相投，才華橫溢的成員，幫助您發展壯大的業務，每天都在努力實現更高的目標。',
        fikirleribirlestir:'連接思想',
        planyap:'制定時間表',
        zamaniyonet:'管理時間',
        mobilcalis:'工作手機',
        dahayuksekbuyumeicingelistirildi:'專為實現更高的業務增長而開發',
        guvenilirhizmetler:'可靠的服務',
        kapsamlidokumanlar:'廣泛的文件',
        musteridestegi:'客戶協助',
        garantilibuyume:'保證增長',
        entegrasyon:'積分',
        musteriyonetimi:'員工管理',
        gelistirme:'發展歷程',
        sosyalmedya:'社交媒體',
        guvenlik:'安全',
        bildirim:'通知',
        optimizasyon:'平台優化',
        webgelistirme:'Web開發',
        uygulamagelistirme:'應用開發',
        arastirma:'研究',
        magazacilik:'零售',
        egitimdestek:'教育支援',
        deneyimliuzmanlar:'經驗豐富的專家',
        zamanindadestek:'準時支持',
        uygunfiyatlar:'負擔得起的價格',
        isgelistirme:'專用態度',
        musteritemsilcisi:'客戶代表',
        teknikegitim:'技術培訓',
        harita:'地圖',
        sirketunvani:'公司名稱',
        kurulusyili:'基礎年',
        adres:'地址',
        telefon:'電話',
        gsm:'手機號碼',
        email:'電子郵件',
        vkn:'稅號',
        faturaadresi:'帐单地址',
        sevkiyatadresi:'送货地址',
        yetkili:'授权人',
        firmakodu:'公司代码',
        firmaadi:'公司名',
        il:'省',
        vergidairesi:'税务局',
        sektor:'部门',
        notlar:'笔记*',
        lisanslar:'发牌',
        secilenlisanslar:'选定的许可证',
        kredikarti:'信用卡',
        kartno:'卡号',
        kartsahibi:'持卡人',
        taksit:'分期付款',
        havale:'汇票/电子转帐',
        kapidaodeme:'货到付款',
        kargoucreti:'运输费用',
        ucretsiz:'自由',
        adet:'数',
        bilgisayar:'计算机',
        nakit:'现金',
        kredikarti:'信用卡卡'


    }
    
    

};

//const currentLocale = 'ar';

// Is it a RTL language?
//export const isRTL = currentLocale.indexOf('he') === 0 || currentLocale.indexOf('ar') === 0;

// Allow RTL alignment in RTL languages
//Vue.I18nManager.allowRTL(isRTL);

const i18n = new VueI18n({
    locale: 'tr', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;

/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js'


import firebase from 'firebase/app' 
import 'firebase/auth'
//import router from '@/router/index.js'
import DataSource from "devextreme/data/data_source";
import {mobilit} from '../../utils/sharedconfig.js'
import notify from 'devextreme/ui/notify';

export default {
  loginAttempt ({ dispatch }, payload) {

    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {

      // Change firebase Persistence
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      // If success try to login
        .then(function () {
          dispatch('login', newPayload)
        })

      // If error notify
        .catch(function (err) {

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    } else {
      // Try to login
      dispatch('login', newPayload)
    }
  },
  login ({ commit, state, dispatch }, payload) {

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })

      return false
    }

    // Try to sigin
    firebase.auth().signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)

      .then((result) => {

        // Set FLAG username update required for updating username
        let isUsernameUpdateRequired = false

        // if username is provided and updateUsername FLAG is true
        // set local username update FLAG to true
        // try to update username
        if (payload.updateUsername && payload.userDetails.displayName) {

          isUsernameUpdateRequired = true

          dispatch('updateUsername', {
            user: result.user,
            username: payload.userDetails.displayName,
            notify: payload.notify,
            isReloadRequired: true
          })
        }

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        // if username update is not required
        // just reload the page to get fresh data
        // set new user data in localstorage
        if (!isUsernameUpdateRequired) {
          router.push(router.currentRoute.query.to || '/')
          commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
        }
      }, (err) => {

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Google Login
  loginWithGoogle ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Facebook Login
  loginWithFacebook ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.FacebookAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Twitter Login
  loginWithTwitter ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.TwitterAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Github Login
  loginWithGithub ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.GithubAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },
  registerUser ({dispatch}, payload) {

    // create user using firebase
    firebase.auth().createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then(() => {
        payload.notify({
          title: 'Account Created',
          text: 'You are successfully registered!',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })

        const newPayload = {
          userDetails: payload.userDetails,
          notify: payload.notify,
          updateUsername: true
        }
        dispatch('login', newPayload)
      }, (error) => {
        payload.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },
  updateUsername ({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, {root: true})

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },


  // JWT
  loginJWT ({ commit }, payload) {
//console.log(payload);
    return new Promise((resolve, reject) => {
      var tbFirma = new DataSource({
        store: mobilit.tbFirmaIletisimi,
        select: [
          "tbFirma.nFirmaID",
          "tbFirma.nHesapID",
          "tbFirma.sKodu",
          "tbFirma.sAciklama",
          "tbFirma.sAdres1",
          "tbFirma.sAdres2",
          "tbFirma.sVergiDairesi",
          "tbFirma.sVergiNo",
          "tbFirma.sSemt",
          "tbFirma.sIl",
          "tbFirma.sSaticiRumuzu"
        ],
        expand:["tbFirma"],
        filter: [["tbFirma.bAktif", "=", true],["nFirmaID","=",Number(payload.userDetails.bayikodu)],["tbFirma.sPostaKodu","=",payload.userDetails.password]],
        searchExpr: ["tbFirma.sAciklama"],        
      });
      // var tbFirmaIletisimi = new DataSource({
      //   store: mobilit.tbFirmaIletisimi,
      //   filter: [["sIletisimAraci", "=", "E-Mail"],["nFirmaID","=",Number(payload.userDetails.bayikodu)],["sIletisimAdresi","=",payload.userDetails.email]],
      // });
      var tbFirmaIletisim = new DataSource({
        store: mobilit.tbFirmaIletisimi,
        filter: [["nFirmaID","=",Number(payload.userDetails.bayikodu)]],
      });
      tbFirma.load().done(function (data) {
        if (data.length) {
          // tbFirmaIletisimi.load().done(function(item) {
          //   if (item.length){
          //     router.push(router.currentRoute.query.to || '/checkout');
          //   }else{console.log('Başarısız')}
          // })
          if (data[0].nFirmaID ==508 || data[0].nFirmaID ==69){
            data[0].sSaticiRumuzu ="";
          }else{
            data[0].sSaticiRumuzu = data[0].nFirmaID;
          };
          let sYetkili=''
          let sTelefon=''
          tbFirmaIletisim.load().done(function (itm){
            let arr = itm;
            
            arr.forEach(function(it){
            if (it.sIletisimAraci=='Yetkili'){
                sYetkili = it.sIletisimAdresi
                
            }else if (it.sIletisimAraci=="Telefon"){
                sTelefon = it.sIletisimAdresi
                
            }
            });
          router.push(router.currentRoute.query.to || '/checkout');
          //console.log(sYetkili)
          //console.log(sTelefon)
          //console.log(data[0]);
          commit('SET_FIRMA',{nFirmaID: data[0].nFirmaID,nHesapID: data[0].tbFirma.nHesapID,sKodu: data[0].tbFirma.sKodu,sAciklama: data[0].tbFirma.sAciklama,sAdres1:data[0].tbFirma.sAdres1,sAdres2:data[0].tbFirma.sAdres2,sVergiDairesi:data[0].tbFirma.sVergiDairesi,sVergiNo:data[0].tbFirma.sVergiNo,sSaticiRumuzu: data[0].tbFirma.sSaticiRumuzu,sSemt: data[0].tbFirma.sSemt,sIl: data[0].tbFirma.sIl,sMail:payload.userDetails.email,sTelefon:sTelefon,sYetkili: sYetkili,sPostaKodu: data[0].tbFirma.sPostaKodu});

          }),
        
          localStorage.setItem('accessToken', data[0].nFirmaID);
          localStorage.setItem("Login","girdi");
          localStorage.setItem("komisyon",0);
          localStorage.setItem("Mod","Bayi");
          localStorage.setItem("menu",0);
          localStorage.setItem("User",JSON.stringify({UserID:data[0].nFirmaID,sKodu: data[0].sKodu,UserName:data[0].sAciklama,FullName:data[0].sAciklama,Email:payload.userDetails.email,sAdres1:data[0].sAdres1,sAdres2:data[0].sAdres2,Phone:sTelefon,Mobile:sTelefon,CariKod:data[0].sKodu,Temsilci:data[0].sSaticiRumuzu,TemsilciEmail:'barkodpos@gmail.com',Aktif:true,SanalPos:false,BakiyesizTahsilat:false,sVergiDairesi:data[0].sVergiDairesi,sVergiNo:data[0].sVergiNo,sSemt: data[0].sSemt,sIl: data[0].sIl,sYetkili:sYetkili}));
          this.$session.set("Login","girdi");
          this.$session.set("User",JSON.stringify({UserID:data[0].nFirmaID,sKodu: data[0].sKodu,UserName:data[0].sAciklama,FullName:data[0].sAciklama,Email:payload.userDetails.email,sAdres1:data[0].sAdres1,sAdres2:data[0].sAdres2,Phone:sTelefon,Mobile:sTelefon,CariKod:data[0].sKodu,Temsilci:data[0].sSaticiRumuzu,TemsilciEmail:'barkodpos@gmail.com',Aktif:true,SanalPos:false,BakiyesizTahsilat:false,sVergiDairesi:data[0].sVergiDairesi,sVergiNo:data[0].sVergiNo,sSemt: data[0].sSemt,sIl: data[0].sIl,sYetkili:sYetkili}));
          commit('UPDATE_USER_INFO', data[0], {root: true});
          commit('SET_BEARER', data[0].nFirmaID);
          resolve(true);
        }
        else {
          notify('Erişim Hatası', "warning", 5000);}
        
      })
      //jwt.login(payload.userDetails.email, payload.userDetails.password)
        //.then(response => {

          // If there's user data in response
          //if (response.data.userData) {
            // Navigate User to homepage
            
            //router.push('/')

            // Set accessToken
            //localStorage.setItem('accessToken', response.data.accessToken)

            // Update user details
            //commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            // Set bearer token in axios
            //commit('SET_BEARER', response.data.accessToken)

            //resolve(response)
          //} else {
            //reject({message: 'Wrong Email or Password'})
          //}

        //})
        //.catch(error => { reject(error) })
    })
  },
  registerUserJWT ({ commit }, payload) {

    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  }
}

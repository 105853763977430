export default {
  actionIcon: 'StarIcon',
  highlightColor: 'warning',
  data: [
    {index: 0, label: 'Home', url: '/', labelIcon: 'HomeIcon', highlightAction: false},
    {index: 1, label: 'Hakkımızda', url: '/about', labelIcon: 'InfoIcon', highlightAction: false},
    {index: 2, label: 'Hizmetlerimiz', url: '/services', labelIcon: 'BookmarkIcon', highlightAction: false},
    {index: 3, label: 'Referanslar', url: '/references', labelIcon: 'UsersIcon', highlightAction: false},
    // {index: 4, label: 'Download', url: '/download', labelIcon: 'DownloadIcon', highlightAction: false},
    {index: 5, label: 'Mağaza', url: '/shop', labelIcon: 'ShoppingBagIcon', highlightAction: false},
    {index: 6, label: 'İletişim', url: '/contact', labelIcon: 'PhoneCallIcon', highlightAction: false},
    
  ]
}

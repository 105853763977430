/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';


//flag icons
//import '@/assets/css/flag-icon.css'

//import '@/assets/Site.css';
//import '@/assets/Style.css';

//devexpress
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuesax Admin Filters
import './filters/filters'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')

// i18n
import i18n from './i18n/i18n'

// ACL
//import acl from './acl/acl'

// Algolia - Instant Search
import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-53024962-1',
  router 
})


import VueGtag from 'vue-gtag'

Vue.use(VueGtag, {
  config: { id: 'G-0G4SSW995W' }
})

//validate
import { ValidationProvider } from 'vee-validate';

//meta tags
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

//ld-json
import VueJsonLD from 'vue-jsonld'
Vue.use(VueJsonLD)

//kayan yazı
import Typewriter from 'vue-typewriter'

Vue.use(Typewriter)

Vue.component('ValidationProvider', ValidationProvider);

Vue.filter("currency", (value) => {
  return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits : 2})
})

router.beforeEach((to, from, next) => {
  if (to.meta.pageTitle != null){
    document.title = to.meta.pageTitle
  }else {document.title='Barkod Sistemi Ankara Ceddan Bilişim'}
  
  next()
})

import Tawk from 'vue-tawk'
Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/60057ca8c31c9117cb6fc19b/1esamtt91'
})

Vue.config.productionTip = false
Vue.config.productionSourceMap = false
Vue.config.devtools = true
Vue.config.performance = true


Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    
    render: h => h(App)
}).$mount('#app')

/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

      {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '',
        component: () => import('./layouts/main/Main.vue'),
        children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
          {
            path: '/',
            name: 'Home',
            component: () => import('./views/pages/KnowledgeBase.vue'),
            meta: {
              //pageTitle:'Barkod Sistemi Ankara Ceddan Bilişim'
            }
          },
          {
            path: '/about',
            name: 'about',
            component: () => import('./views/pages/Profile.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'About', active: true },
              ],
              pageTitle: 'Hakkımızda',
              
            }
          },
          {
            path: '/services',
            name: 'services',
            component: () => import('./components/pages/services.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Services', active: true },
              ],
              pageTitle: 'Hizmetlerimiz',
              rule: 'editor'
            }
          },
          {
            path: '/blank',
            name: 'blank',
            component: () => import('./views/pages/Invoice.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Mağaza',url:'/shop', active: true },
                { title: 'Sipariş', active: true },
              ],
              pageTitle: 'Sipariş',
              rule: 'editor'
            }
          },
          {
            path: '/faq',
            name: 'faq',
            component: () => import('./views/pages/Faq.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Faq', active: true },
              ],
              pageTitle: 'Sık Sorulan Sorular',
              rule: 'editor'
            }
          },
          {
            path: '/support',
            name: 'support',
            component: () => import('./views/pages/Support.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Destek', active: true },
              ],
              pageTitle: 'Destek',
              rule: 'editor'
            }
          },
          {
            path: '/barkod-sistemi',
            name: 'barkod-sistemi',
            component: () => import('./views/pages/barkodsistemi.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Barkod Sistemi', active: true },
              ],
              pageTitle: 'Barkod Sistemi',
              rule: 'editor'
            }
          },
          {
            path: '/barkod-sistemi-ankara',
            name: 'barkod-sistemi-ankara',
            component: () => import('./views/pages/barkodsistemiankara.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Barkod Sistemi Ankara', active: true },
              ],
              pageTitle: 'Barkod Sistemi Ankara',
              rule: 'editor'
            }
          },
          {
            path: '/download',
            name: 'download',
            component: () => import('./components/pages/download.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Download', active: true },
              ],
              pageTitle: 'İndir,İzle,Öğren',
              rule: 'editor'
            }
          },
          {
            path: '/references',
            name: 'references',
            component: () => import('./components/pages/references.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'References', active: true },
              ],
              pageTitle: 'Referanslar',
              rule: 'editor'
            }
          },
          {
            path: '/contact',
            name: 'contact',
            component: () => import('./components/pages/contact.vue'),
            meta: {
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'İletişim', active: true },
              ],
              pageTitle: 'İletişim',
              rule: 'editor'
            }
          }, 
          {
           path: '/shop',
           name: 'shop',
           component: () => import('./views/apps/eCommerce/ECommerceShop.vue'),
           pageTitle: 'Mağaza',
           meta : {
             pageTitle:'Mağaza',
             contentClass: 'ecommerce-application',
            breadcrumb: [
              {
                title: 'Home',
                text: 'Home',
                url :'/'
              },
              {
                title: 'Mağaza',
                text: 'Mağaza',
                url:'/shop',
                active: true,
              }
            ]
            }
          },
          {
            path: '/shop/:sSinifKodu5',
            name: 'shopcategory',
            component: () => import('./views/apps/eCommerce/ECommerceShop.vue'),
            pageTitle: 'Mağaza',
            meta : {
              //pageTitle:'Mağaza'
             }
           },
         
        {
          path: '/urun/:item_id/:sSinifKodu/:sAciklama',
          name: 'product',
          component: () => import('./views/apps/eCommerce/ECommerceProductDetails.vue'),
          meta: {
            pageTitle: 'Ürün Detayları',
            contentClass: 'ecommerce-application',
            breadcrumb: [
              {
                title: 'Home',
                text: 'Home',
                url :'/'
              },
              {
                title: 'Mağaza',
                text: 'Mağaza',
                url:'/shop',
                active: false,
              },
              {
              text: 'Mağaza',
                active: true,
              }
              
            ],
          },
        },
          {
            path: '/checkout',
            name: 'eCommerceCheckout',
            component: () => import('./views/apps/eCommerce/ECommerceCheckout.vue'),
            meta: {
              pageTitle: 'Kasa',
              contentClass: 'ecommerce-application',
              breadcrumb: [
                {
                  title: 'Home',
                  text: 'ECommerce',
                  url :'/'
                },
                {
                  title: 'Mağaza',
                  text: 'Mağaza',
                  url:'/shop',
                  active: false,
                },
                {
                  title: 'Kasa',
                  text: 'Kasa',
                  url:'/checkout',
                  active: true,
                }
                
              ],
            },
          },
          {
            path: '/wish-list',
            name: 'eCommerceWishList',
            component: () => import('./views/apps/eCommerce/ECommerceWishList.vue')
          },
          
          {
            path: '/404',
            name: 'error',
            component: () => import('./views/pages/Error404.vue')
          },
          {
            path: '*',
            redirect: '/404'
          }
        ],
      },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
      {
        path: '',
        component: () => import('@/layouts/full-page/FullPage.vue'),
        children: [
      // =============================================================================
      // PAGES
      // =============================================================================
          {
            path: '/pages/login',
            name: 'pageLogin',
            component: () => import('@/views/pages/Login.vue')
          },
          {
            path: '/error404',
            name: 'error404',
            component: () => import('./views/pages/Error404.vue')
          },
        ]
      },
      // Redirect to 404 page, if no match found
      {
        path: '*',
        redirect: '/error404'
      }
    ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

//import { DxStateStoring } from "devextreme-vue/data-grid"


export default {
  getFirmaResult: state =>{
    return {
      nFirmaID : state.nFirmaID, 
      nHesapID: state.nHesapID,
      sKodu : state.sKodu,
      sAciklama : state.sAciklama,
      sAdres1: state.sAdres1,
      sAdres2: state.sAdres2,
      sVergiDairesi: state.sVergiDairesi,
      sVergiNo: state.sVergiNo,
      sSaticiRumuzu: state.sSaticiRumuzu,
      sSemt: state.sSemt,
      sIl : state.sIl,
      sMail: state.sMail,
      sYetkili: state.sYetkili,
      sTelefon: state.sTelefon,
      sPostaKodu: state.sPostaKodu
    }
  }
}

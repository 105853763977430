/*=========================================================================================
  File Name: moduleEcommerceGetters.js
  Description: Ecommerce Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    isInCart: state => itemId => {
      return state.cartItems.some((item) => item.nStokID === itemId)
    },
    isInWishList: state => itemId => {
      return state.wishList.some((item) => item.nStokID === itemId)
    },
    getCartItem: state => itemId => {
      const result = state.cartItems.filter((item) => item.nStokID === itemId)
      return result.length ? result.pop() : []
    },
    getTradeResult: state =>{
      return {
        bLisans : state.bLisans,
        bFirma : state.bFirma,
        bBilgisayar : state.bBilgisayar,
        lDovizKuru : state.lDovizKuru,
        lToplamMiktar : state.lToplamMiktar,
        lMalBedeli : state.lMalBedeli,
        lMalIskontoTutari: state.lMalIskontoTutari,
        lToplamKdv : state.lToplamKdv,
        lNetTutar: state.lNetTutar,
        bLisansYillik: state.bLisansYillik,
        bLisansAylik: state.bLisansAylik,
        bLisansTemizle: state.bLisansTemizle
        
      }
    }
  }
  